/*
    Styles du composant [selects] de SipaUI
*/
@import "variables";

.sipaui {
    .su-switch {
        display: inline-flex;
        height: auto;
        align-items: center;
        
        input[type=checkbox] {
            position: relative;
            align-self: flex-start;
                width: $width-switch;
                min-width: $width-switch; // indispensable pour prréserver la largeur au flex-item
                height: $height-switch;
                background: $background-switch;
                border-radius: $border-radius-switch;
            
            &:before {
                content: "";
                display: block;
                width: $width-switch-push;
                height: $height-switch-push;
                position: absolute;
                left: $position-switch-push;
                top: 50%;
                transform: translateY(-50%);
                background: $background-switch-push;
                border-radius: $border-radius-switch-push;
                transition: left .2s ease;
            }
            &:checked {
                background: $background-switch-checked;
                
                &:before {
                    left: $position-switch-push-checked;
                }
            }
        }
        input[type=checkbox] + span,
        input[type=checkbox] + label,
        input[type=checkbox] + em,
        input[type=checkbox] + strong,
        span + input[type=checkbox],
        label + input[type=checkbox],
        em + input[type=checkbox],
        strong + input[type=checkbox] {
            margin-left: var(--marge-s);
        }
        &.su-no-label {
            input[type=checkbox] + span,
            input[type=checkbox] + label {
                position: absolute !important;
                clip: rect(1px, 1px, 1px, 1px);
                padding: 0 !important;
                margin: 0 !important;
                border: 0 !important;
                height: 1px !important;
                width: 1px !important;
                overflow: hidden;
            }
        }
        &.su-theme input[type=checkbox]:checked {
            background: $background-switch-checked-theme;
        }
    }
    label.su-switch ,
    .su-switch label {
        margin-bottom: 0;
        height: auto;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
    }
}
