/*
    Styles du composant [text-fields] de SipaUI
*/
@import "variables";

.sipaui {
    $size-button: var(--taille-tactile);

    input,
    textarea,
    .su-input-text {
        font-size: $font-size-standard;

        &[readonly] + .su-input-actions-area {
            display: none;
        }
    }
    #{$inputTextBase} {
        @include appearance(none);
    }
    #{$inputTextPlus} {
        display: block;
        width: 100%;
        min-width: 140px;
        height: $height-input;
        border-radius:  $border-radius-input;
        color: $color-input;
        background: $background-input;
        border: $border-input;
        transition: background .3s ease-out, border .3s ease-out;
        padding: 0 $padding-lateral-input;

        &:hover {
            background: $background-input-hover;
            border: $border-input-hover;
        }
        &:focus {
            background: $background-input-focus;
            border: $border-input-focus;
        }

        &[readonly] {
            opacity: var(--opacite-inactif);
            cursor: default;

            &:hover,
            &:focus {
                background: $background-input;
                border: $border-input;
            }
        }
    }
    .su-label-icon {
        position: relative;
        
        label {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            height: $height-input;
            width: $height-input;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-label-icon;
        }
        input {
            padding-left: $padding-left-input-with-label-icon;
        }
        &:has(input:not([readonly]):hover) label {
            color: $color-label-icon-hover;
        }
        &:has(input:not([readonly]):focus) label {
            color: $color-label-icon-focus;
        }
    }
    .su-input-actions-1,
    .su-input-actions-2 {
        position: relative;
        
        [type="text"]:invalid + .su-input-actions-area, // Ne cible que les input-text invalides parce que les autres inputs ont plus de causes d’invalidité que le simple fait d’être vide…
        input:placeholder-shown + .su-input-actions-area {
            display: none;
        }
        
        input:blank + .su-input-actions-area { // Doit être séparer de la déclaration du dessus tant que :blank n’est pas pris en compte par les navigateurs pour ne pas la "casser"…
            display: none;
        }
    }
    .su-input-actions-1 {
        #{$inputTextBase} {
            padding-right: $size-button;
        }
    }
    .su-input-actions-2 {
        #{$inputTextBase} {
            padding-right: calc(#{$size-button} * 2);
        }
    }
    .su-input-actions-area {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
    }
    .su-input-action {
        display: flex; // sert à centrer verticalement l’icône;
        justify-content: center;
        align-items: center;
        height: $size-button;
        width: $size-button;
        border: none;
        background: none;
        cursor: pointer;
        color: $color-picto;
        @include appearance(none);
        cursor: text;

        &:hover {
            color: $color-picto-hover;
        }
        &:focus,
        &:active {
            color: $color-picto-active;
        }
    }
    .su-js-show .su-input-actions-area {
        opacity: 1;

        .su-input-action {
            cursor: pointer;
        }
    }
    textarea,
    .su-textarea {
        padding: 10px 15px;
        min-height: 68px;
    }
}














