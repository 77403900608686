/*
    Styles du composant [items list] de SipaUI
*/
@import "variables";


// GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-items-list {      
        li {
            display: flex;
            min-height: var(--taille-tactile);
            border-bottom: $border-bottom-items-list;
            padding: $padding-items-list;
            -webkit-column-break-inside: avoid; //nécessaire pour éviter un bug sur Chrome qui coupe avant la bordure quand on l’utilise dans des columns…
                           break-inside: avoid;
            
            a {
                page-break-inside: avoid; //nécessaire pour éviter un bug sur Firefox qui coupe avant la bordure quand on l’utilise dans des columns…
                     break-inside: avoid;
                .su-icon,
                [class*="su-flag-"],
                svg {
                    vertical-align: middle;
                    transform: translateY(-1px);
                    display: inline-block;
                    margin-right: $margin-right-icon-items-list;
                }
                &:hover,
                &:focus {
                    svg,
                    svg path {
                        fill: $color-link-standard-hover !important;
                        stroke: none !important;
                    }
                }
            }
        }
        &.su-no-ending-border, // déprécier à supprimer en 7.0 ?
        &.su-no-ending-separator {
            li:last-child {
                border-bottom: none;
            }
        }
        &[class*="su-columns-"].su-no-ending-border, // déprécier à supprimer en 7.0 ?
        &[class*="su-columns-"].su-no-ending-separator {
            overflow: hidden;
            
            li {
                border-bottom: none;
                border-top: $border-bottom-items-list;
                transform: translateY($translateY-items-list-colonnes)
            }
        }
        &:not(.su-preserve-colors) {
            a {
                svg,
                svg path {
                    fill: $color-link-standard !important;
                    stroke: none !important;
                }
            }
        }
        &:not(.su-preserve-size) {
            a {
                svg {
                    width: $width-icon-items-list  !important;
                    height: $height-icon-items-list !important;
                }
            }
        }
    }
}
