/*
    Styles du composant [Tags] de SipaUI
*/
@import "variables";

@mixin su-tag-contrast {
    background: $background-tag-contrast;
}

.sipaui {
    .su-tag {
        display: inline-block;
        height: $height-tag;
        line-height: $height-tag;
        padding: $padding-tag;
        border-radius: $border-radius-tag;
        border: $border-tag;
        background: $background-tag;
        font-size: $font-size-tag;
        font-style: $font-style-tag;
        font-weight: $font-weight-tag;
        color: $color-tag;
        opacity: $opacity-tag;
        cursor: pointer;
        //CSS pour bloquer le contenu trop long dans un tag
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        vertical-align: top; //empêche une "fausse marge" basse provoquée par l’overflow
        
        
        &:hover {
            background: $background-tag-hover;
            color: $color-tag-hover;
            opacity: $opacity-tag-hover;
        }
        &:active,
        &:focus {
            background: $background-tag;
            color: $color-tag;
            opacity: $opacity-tag;
        }
    }
    .su-tag.su-contrast {
        @include su-tag-contrast;
    }
    
    .su-tags {
        display: flex;
        flex-flow: row wrap;
        gap: $gap-tag;
        padding: 0;
        margin: 0;

        li {
            white-space: nowrap;
            
            a,
            a:visited {
                @extend .su-tag
            }
        }
        &.su-contrast {
            li a {
                @include su-tag-contrast;
            }
        }
    }
    .su-carrousel .su-tags {   //Surcharge du Carrousel
        gap: $gap-tag;
    }
    .su-contrast {
        .su-tags {
            li a {
                @include su-tag-contrast;
            }
        }
    }
}



// CARROUSEL
.su-touch {
    .su-carrousel {
        .su-tags {
            mask-image: $mask-image-carrousel-tags;
        }
    }
}
