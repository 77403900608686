/*
    Styles du composant [social-buttons] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    .su-social-button {
        display: inline-flex;
        align-items: center;
        padding: 0; // Pour surcharger les styles de bouton par défaut
        color: $font-color-social-button;
        
        .su-icon {
            height: $size-touch-sm;
            width: $size-touch-sm;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $font-color-social-button;
            
            & + span {
                margin-left: $gap-name-social-button;
            }
        }
        
        &:hover,
        &:focus,
        &:active {
            color: $font-color-social-button-hover;
            opacity: $opacity-hover;
        }
        &.su-facebook,
        &.su-x,
        &.su-instagram,
        &.su-flipboard,
        &.su-linkedin,
        &.su-rss,
        &.su-mobile-app,
        &.su-mail {
            .su-icon {
                color: $color-social-button-with-background;
                background: $background-social-button-with-background;
                border-radius: $border-radius-social-button-with-background;
                font-size: $font-size-social-button-with-background;
            }
        }
        &.su-facebook {
            .su-icon {
                background: var(--facebook)
            }
        }
        &.su-x {
            .su-icon {
                background: var(--x)
            }
        }
        &.su-instagram {
            .su-icon {
                background: var(--instagram)
            }
        }
        &.su-flipboard {
            .su-icon {
                background: var(--flipboard)
            }
        }
        &.su-linkedin {
            .su-icon {
                background: var(--linkedin)
            }
        }
        &.su-rss {
            .su-icon {
                background: var(--fluxrss)
            }
        }
    }
    .su-negative .su-social-button,
    .su-social-button.su-negative {
        .su-icon {
            color: $font-color-negative;
        }
    }
}
