/*
    Styles du composant [Input Group] de SipaUI
*/
@import "variables";

.sipaui {
    .su-input-group {
        display: flex;
        
        input {
            border-radius: $border-radius-input-group-input;
            
            &,
            &:hover,
            &:focus,
            &:active {
                border-right: 0;
            }
        }
        input,
        .su-input-actions-2,
        .su-input-actions-1 {
            flex: 1;
        }
        .su-button {
            min-width: $min-width-input-group-button;
            border-radius: $border-radius-input-group-button;
            padding: $padding-input-group-button;
            
            &.su-action-icon svg {
                margin: 0;
            }
        }
    }
}
