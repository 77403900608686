/*
    Styles du composant [links] de SipaUI
*/
@import "variables";


body a {
    text-decoration-skip: auto !important; // Safari
    text-decoration-skip-ink: auto !important; // Les autres
}

// LIEN NON ÉDITORIAL ------------------------------------------------------------------------------------------/
// L’essentiel est dans typo.scss

// Lien léger
.sipaui {
    a.su-link-light {
        color: inherit;
        text-decoration: underline;
        border: none;
        
        &:hover,
        &:focus,
        &:active {
            color: inherit;
            text-decoration: underline;
            opacity: $opacity-hover-link-light;
        }
    }
}

// LIEN ÉDITORIAL ------------------------------------------------------------------------------------------/
.sipaui {
    .su-article a,
    a.su-link {
        color: $color-link-article;
        text-decoration: $text-decoration-link-article;
        padding-bottom: 1px;
        border-bottom: $border-bottom-link-article;

        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $color-link-article-hover;
            text-decoration: $text-decoration-link-article-hover;
            border-color: $border-color-link-article-hover;
        }
        &.su-disabled {
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: $color-link-article;
                border-color: $color-link-article;
            }
        }
    }
    // Fond sombre
    .su-article.su-negative a,
    .su-article .su-negative a,
    a.su-link.su-negative {
        color: $color-link-article-negative;
        border-bottom: $border-bottom-link-article-negative;
    
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $color-link-article-negative-hover;
            border-color: $border-color-link-article-negative-hover;
        }
    }
    // Lien léger
    .su-article a.su-link-light {
        color: inherit;
        text-decoration: underline;
        border: none;
        
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $color-link-article-hover;
        }
    }

    // Lien invisible
    .su-article a.su-not-link,
    a.su-not-link {
        color: $font-color-standard;
        text-decoration: none;
        padding-bottom: 0;
        border-bottom: none;
    }
}


// LIEN BOUTON ------------------------------------------------------------------------------------------/
.sipaui {
    a.su-link-button {
        color: $color-link-button;
        text-decoration: $text-decoration-link-button;
        font-weight: $font-weight-link-button;
        font-size: $font-size-link-button;
        display: inline-block;
        height: $height-link-button;
        line-height: $height-link-button;
        border-bottom: 0;

        &:hover,
        &:focus,
        &:active {
            color: $color-link-button-hover;
            text-decoration: $text-decoration-link-button-hover;
        }
        &.su-disabled {
            &:hover,
            &:focus,
            &:active {
                color: $color-link-button;
                text-decoration: $text-decoration-link-button;
            }
        }
    }
}

