/*
    Styles du composant [grids] de SipaUI
*/
@import "variables";

// CONTENEUR------------------------------------------------------------------------------------------/
.sipaui .su-container,
.sipaui.su-container {
    width: 100%;
    padding: 0 $marge-container-xs;
}


@media screen and (min-width: $min-breakpoint-sm) {
    .sipaui .su-container,
    .sipaui.su-container {
        padding: 0 $marge-container-sm;
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui .su-container,
    .sipaui.su-container {
        max-width: $max-width-container-md; /* 768px */
        margin: 0 auto;
        padding: 0 $marge-container-md;
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui .su-container,
    .sipaui.su-container {
        max-width: $max-width-container-lg; /* 960px */
        padding: 0 $marge-container-lg;
    }
}

@media screen and (min-width: $min-breakpoint-xl) {
    .sipaui .su-container,
    .sipaui.su-container {
        max-width: $max-width-container-xl; /* 1280px */
    }
}


// GRILLE------------------------------------------------------------------------------------------/
.sipaui .su-row,
.sipaui.su-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
    &:not(.su-grid-auto) {
        column-gap: $grid-gutter;
    }

    @include grid-col-definition(xs, $grid-small-max-col);

    @include grid-offset-definition(xs, $grid-small-max-col, $grid-gutter);

    @include grid-filling-definition(xs, $grid-small-max-col, $grid-gutter);

    // Grille auto
    &.su-grid-auto {
        flex-wrap: nowrap;

        &.su-wrap {
            flex-wrap: wrap;
            justify-content: flex-start;

            .su-col {
                flex: 0 0 auto;
            }
        }
        .su-col {
            flex: 1 1 auto;
        }
        &.su-has-gutter,
        &.su-column-gap {
            column-gap: $grid-gutter;
        }
        &.su-row-right {
            justify-content: flex-end;
        }
        &.su-row-center {
            justify-content: center;
        }
        &.su-row-right,
        &.su-row-center {
            .su-col {
                flex: 0 0 auto;
            }
        }
    }

    // Alignement vertical
    &.su-vertical-start {
        align-items: flex-start;
    }
    &.su-vertical-center {
        align-items: center;
    }
    &.su-vertical-end {
        align-items: flex-end;
    }
    
    // Gouttière horizontale
    &.su-row-gap {
       row-gap: $grid-gutter;
    }

    [class*="su-col-"] {
        flex: 1 1 100%;
        min-width: 0;
    }
}


@media screen and (min-width: $min-breakpoint-sm) {
    .sipaui .su-row,
    .sipaui.su-row {
        @include grid-col-definition(sm, $grid-small-max-col);
        @include grid-offset-definition(sm, $grid-small-max-col, $grid-gutter);
        @include grid-filling-definition(sm, $grid-small-max-col, $grid-gutter);
        .su-offset-sm-0 {
            margin-left:0;
        }
        .su-filling-sm-0 {
            margin-right:0;
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui .su-row,
    .sipaui.su-row {
        @include grid-col-definition(md, $grid-large-max-col);
        @include grid-offset-definition(md, $grid-large-max-col, $grid-gutter);
        @include grid-filling-definition(md, $grid-large-max-col, $grid-gutter);
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui .su-row,
    .sipaui.su-row {
        @include grid-col-definition(lg, $grid-large-max-col);
        @include grid-offset-definition(lg, $grid-large-max-col, $grid-gutter);
        @include grid-filling-definition(lg, $grid-large-max-col, $grid-gutter);
    }
}

@media screen and (min-width: $min-breakpoint-xl) {
    .sipaui .su-row,
    .sipaui.su-row {
        @include grid-col-definition(xl, $grid-large-max-col);
        @include grid-offset-definition(xl, $grid-large-max-col, $grid-gutter);
        @include grid-filling-definition(xl, $grid-large-max-col, $grid-gutter);
    }
}
