/*
    Styles du composant [card] de SipaUI
*/
@import "variables";
@import "../../Text/Titles/variables";
@import "../../Text/Sources/variables";
@import "../../Text/Date/variables";


// FOCUS STANDARD
.sipaui {
    .su-card {
        background: $background-card;
        padding: $padding-card;
        border-radius: $border-radius-card;
        overflow: hidden;
        align-self: flex-start;
        
        .su-media-container,
        > figure { // a supprimer en v7 (su-media-container obligatoire)
            margin: $margin-figure-card;
        }
        .su-main-picture {
            border-radius: 0;
        }
        .su-title {
            margin-bottom: $margin-bottom-titre-card;
        }
    }
    .su-card.su-equal {
        align-self: stretch;
    }
    
    @if $do-not-use-negative == null {
        .su-negative {
            .su-card {
                h2,
                h2 a,
                .su-title,
                .su-title a {
                    color: $color-titles;
                }
                h2 a,
                .su-title a {
                    &:hover {
                        color: $color-titles-hover;
                    }
                }
                .su-source {
                    color: $color-source;
                }
                .su-date {
                    color: $color-date;
                }
                .su-date::before {
                    background: $color-date;
                }
            }
        }
    }
}
