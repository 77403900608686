/*
    Styles du composant [sources] de SipaUI
*/
@import "variables";

// COCARDES
/*
Par défaut: on cherche sur le CDN;

mais pour travailler en local il suffit de faire
$url-cocardes: '/FRAMEWORK/2COMPOSANTS/Text/Sources/images/';

*/
$url-cocardes: 'https://cdn.sipaof.fr/sipaui/latest/downloads/cocardes/' !default;
//$url-cocardes: '/FRAMEWORK/2COMPOSANTS/Text/Sources/images/'; // version pour dev en local

.sipaui {
    [class*="su-cockade-"] {
        background: no-repeat;
        background-size: 100%;
        display: inline-block;
        min-height: $taille-cocarde;
        min-width: $taille-cocarde;
    }
    .su-cockade-5p {
        background-image: url('#{$url-cocardes}5p.svg');
    }
    .su-cockade-a {
        background-image: url('#{$url-cocardes}a.svg');
    }
    .su-cockade-af {
        background-image: url('#{$url-cocardes}af.svg');
    }
    .su-cockade-api {
        background-image: url('#{$url-cocardes}api.svg');
    }
    .su-cockade-b {
        background-image: url('#{$url-cocardes}b.svg');
    }
    .su-cockade-bcd {
        background-image: url('#{$url-cocardes}bcd.svg');
    }
    .su-cockade-bec {
        background-image: url('#{$url-cocardes}bec.svg');
    }
    .su-cockade-ca {
        background-image: url('#{$url-cocardes}ca.svg');
    }
    .su-cockade-clr {
        background-image: url('#{$url-cocardes}clr.svg');
    }
    .su-cockade-cm {
        background-image: url('#{$url-cocardes}cm.svg');
    }
    .su-cockade-co {
        background-image: url('#{$url-cocardes}co.svg');
    }
    .su-cockade-cp {
        background-image: url('#{$url-cocardes}cp.svg');
    }
    .su-cockade-cr {
        background-image: url('#{$url-cocardes}cr.svg');
    }
    .su-cockade-d {
        background-image: url('#{$url-cocardes}d.svg');
    }
    .su-cockade-eo {
        background-image: url('#{$url-cocardes}eo.svg');
    }
    .su-cockade-eof {
        background-image: url('#{$url-cocardes}eof.svg');
    }
    .su-cockade-f1o {
        background-image: url('#{$url-cocardes}f1o.svg');
    }
    .su-cockade-ffv {
        background-image: url('#{$url-cocardes}ffv.svg');
    }
    .su-cockade-fl {
        background-image: url('#{$url-cocardes}fl.svg');
    }
    .su-cockade-hw {
        background-image: url('#{$url-cocardes}hw.svg');
    }
    .su-cockade-i {
        background-image: url('#{$url-cocardes}i.svg');
    }
    .su-cockade-jdl {
        background-image: url('#{$url-cocardes}jdl.svg');
    }
    .su-cockade-jj {
        background-image: url('#{$url-cocardes}jj.svg');
    }
    .su-cockade-k {
        background-image: url('#{$url-cocardes}k.svg');
    }
    .su-cockade-le {
        background-image: url('#{$url-cocardes}le.svg');
    }
    .su-cockade-lm {
        background-image: url('#{$url-cocardes}lm.svg');
    }
    .su-cockade-lmag {
        background-image: url('#{$url-cocardes}lmag.svg');
    }
    .su-cockade-lp {
        background-image: url('#{$url-cocardes}lp.svg');
    }
    .su-cockade-mda {
        background-image: url('#{$url-cocardes}mda.svg');
    }
    .su-cockade-mdchat {
        background-image: url('#{$url-cocardes}mdchat.svg');
    }
    .su-cockade-mdchien {
        background-image: url('#{$url-cocardes}mdchien.svg');
    }
    .su-cockade-mif {
        background-image: url('#{$url-cocardes}mif.svg');
    }
    .su-cockade-ml {
        background-image: url('#{$url-cocardes}ml.svg');
    }
    .su-cockade-o {
        background-image: url('#{$url-cocardes}o.svg');
    }
    .su-cockade-oe {
        background-image: url('#{$url-cocardes}oe.svg');
    }
    .su-cockade-of {
        background-image: url('#{$url-cocardes}of.svg');
    }
    .su-cockade-or {
        background-image: url('#{$url-cocardes}or.svg');
    }
    .su-cockade-pb {
        background-image: url('#{$url-cocardes}pb.svg');
    }
    .su-cockade-ph {
        background-image: url('#{$url-cocardes}ph.svg');
    }
    .su-cockade-po {
        background-image: url('#{$url-cocardes}po.svg');
    }
    .su-cockade-ta {
        background-image: url('#{$url-cocardes}ta.svg');
    }
    .su-cockade-tam {
        background-image: url('#{$url-cocardes}tam.svg');
    }
    .su-cockade-tc {
        background-image: url('#{$url-cocardes}tc.svg');
    }
    .su-cockade-tdj {
        background-image: url('#{$url-cocardes}tdj.svg');
    }
    .su-cockade-tm {
        background-image: url('#{$url-cocardes}tm.svg');
    }
    .su-cockade-tn {
        background-image: url('#{$url-cocardes}tn.svg');
    }
    .su-cockade-tr {
        background-image: url('#{$url-cocardes}tr.svg');
    }
    .su-cockade-vao {
        background-image: url('#{$url-cocardes}vao.svg');
    }
    .su-cockade-vv {
        background-image: url('#{$url-cocardes}vv.svg');
    }
}



// SOURCE
.sipaui {
    .su-source {
        font-size: $font-size-source-article;
        color: $color-source;
        line-height: $line-height-source;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &[class*="su-cockade-"] {
            background-size: $taille-cocarde;
            background-position: 0;
            padding-left: calc(#{$taille-cocarde} + #{$marge-cocarde});
        }
    }

    a.su-source,
    .su-source a,
    .su-article a.su-source,
    .su-article .su-source a {
        border-bottom: none;
        color: $color-lien;
        
        &:hover {
            color: $color-lien-hover;
        }
    }

    .su-articles-list,
    .su-articles-area {
        .su-source {
            font-size: $font-size-source-liste;
            
            &[aria-description]:empty:after {
                content: attr(aria-description);
            }
            &:empty:not([aria-description], [aria-label]) {
                                // &:empty:not([aria-description]) { temporaire, pour la rétro-compatibilité avec aria-label
                padding: 0;
            }
                                // temporaire, pour la rétro-compatibilité avec aria-label
                                &[aria-label]:empty::after {
                                    content: attr(aria-label);
                                }
                                //
            &[class*="su-cockade-"] {
                background-size: $taille-cocarde-liste;
                background-position: 0;
                padding-left: calc(#{$taille-cocarde-liste} + #{$marge-cocarde});
            }
        }
    }
    @if $do-not-use-negative == null {
        .su-negative .su-source,
        .su-negative.su-source {
            color: $font-color-negative;
        }
    }
}
