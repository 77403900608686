/*
    Styles du composant [media-icons] de SipaUI
*/
@import "variables";


/*
Par défaut: on cherche sur le CDN;

mais pour travailler en local il suffit de faire
$url-drapeaux: '/FRAMEWORK/2COMPOSANTS/Text/Sources/images/';

*/
$url-drapeaux: 'https://cdn.sipaof.fr/sipaui/latest/downloads/drapeaux/' !default;
//$url-drapeaux: '/FRAMEWORK/2COMPOSANTS/Media/Flags/svg/' !default; // version pour dev en local


// DRAPEAUX --------------------------------------------------------------------------------------/
.sipaui {
    [class*="su-flag-"] {
        display: inline-block;
        overflow: hidden;
        text-indent: -1000px;
        background-repeat: no-repeat;
        background-size: 100%;
        height: $height-drapeau;
        width: $width-drapeau;
        
        &:not(.su-no-border) {
            box-shadow: $box-shadow-drapeau;
        }
    }
    .su-flag-af {                           // Afghanistan
        background-image: url('#{$url-drapeaux}afghanistan.svg');
    }
    .su-flag-za {                           // Afrique-du-sud
        background-image: url('#{$url-drapeaux}afrique-du-sud.svg');
    }
    .su-flag-al {                           // Albanie
        background-image: url('#{$url-drapeaux}albanie.svg');
    }
    .su-flag-dz {                           // Algerie
        background-image: url('#{$url-drapeaux}algerie.svg');
    }
    .su-flag-de {                           // Allemagne
        background-image: url('#{$url-drapeaux}allemagne.svg');
    }
    .su-flag-ad {                           // Andorre
        background-image: url('#{$url-drapeaux}andorre.svg');
    }
    .su-flag-eng {                          // Angleterre
        background-image: url('#{$url-drapeaux}angleterre.svg');
    }
    .su-flag-ao {                           // Angola
        background-image: url('#{$url-drapeaux}angola.svg');
    }
    .su-flag-ag {                           // Antigua-et-barbuda
        background-image: url('#{$url-drapeaux}antigua-et-barbuda.svg');
    }
    .su-flag-sa {                           // Arabie-saoudite
        background-image: url('#{$url-drapeaux}arabie-saoudite.svg');
    }
    //-------------------------------------------------------------------- 10
    .su-flag-ar {                            // Argentine
        background-image: url('#{$url-drapeaux}argentine.svg');
    }
    .su-flag-am {                            // Armenie
        background-image: url('#{$url-drapeaux}armenie.svg');
    }
    .su-flag-au {                            // Australie
        background-image: url('#{$url-drapeaux}australie.svg');
    }
    .su-flag-at {                            // Autriche
        background-image: url('#{$url-drapeaux}autriche.svg');
    }
    .su-flag-az {                            // Azerbaidjan
        background-image: url('#{$url-drapeaux}azerbaidjan.svg');
    }
    .su-flag-bs {                            // Bahamas
        background-image: url('#{$url-drapeaux}bahamas.svg');
    }
    .su-flag-bh {                            // Bahrain
        background-image: url('#{$url-drapeaux}bahrain.svg');
    }
    .su-flag-bd {                            // Bangladesh
        background-image: url('#{$url-drapeaux}bangladesh.svg');
    }
    .su-flag-bb {                            // Barbade
        background-image: url('#{$url-drapeaux}barbade.svg');
    }
    .su-flag-be {                            // Belgique
        background-image: url('#{$url-drapeaux}belgique.svg');
    }
    //-------------------------------------------------------------------- 20
    .su-flag-bz {                            // Belize
        background-image: url('#{$url-drapeaux}belize.svg');
    }
    .su-flag-bj {                            // Benin
        background-image: url('#{$url-drapeaux}benin.svg');
    }
    .su-flag-bt {                            // Bhoutan
        background-image: url('#{$url-drapeaux}bhoutan.svg');
    }
    .su-flag-by {                            // Bielorussie
        background-image: url('#{$url-drapeaux}bielorussie.svg');
    }
    .su-flag-mm {                            // Birmanie
        background-image: url('#{$url-drapeaux}birmanie.svg');
    }
    .su-flag-bo {                            // Bolivie
        background-image: url('#{$url-drapeaux}bolivie.svg');
    }
    .su-flag-ba {                            // Bosnie-herzegovine
        background-image: url('#{$url-drapeaux}bosnie-herzegovine.svg');
    }
    .su-flag-bw {                            // Botswana
        background-image: url('#{$url-drapeaux}botswana.svg');
    }
    .su-flag-br {                            // Bresil
        background-image: url('#{$url-drapeaux}bresil.svg');
    }
    .su-flag-bn {                            // Brunei
        background-image: url('#{$url-drapeaux}brunei.svg');
    }
    //-------------------------------------------------------------------- 30
    .su-flag-bg {                            // Bulgarie
        background-image: url('#{$url-drapeaux}bulgarie.svg');
    }
    .su-flag-bf {                            // Burkina
        background-image: url('#{$url-drapeaux}burkina.svg');
    }
    .su-flag-bi {                            // Burundi
        background-image: url('#{$url-drapeaux}burundi.svg');
    }
    .su-flag-kh {                            // Cambodge
        background-image: url('#{$url-drapeaux}cambodge.svg');
    }
    .su-flag-cm {                            // Cameroun
        background-image: url('#{$url-drapeaux}cameroun.svg');
    }
    .su-flag-ca {                            // Canada
        background-image: url('#{$url-drapeaux}canada.svg');
    }
    .su-flag-cv {                            // Cap-vert
        background-image: url('#{$url-drapeaux}cap-vert.svg');
    }
    .su-flag-cl {                            // Chili
        background-image: url('#{$url-drapeaux}chili.svg');
    }
    .su-flag-cn {                            // Chine
        background-image: url('#{$url-drapeaux}chine.svg');
    }
    .su-flag-cy {                            // Chypre
        background-image: url('#{$url-drapeaux}chypre.svg');
    }
    //-------------------------------------------------------------------- 40
    .su-flag-co {                            // Colombie
        background-image: url('#{$url-drapeaux}colombie.svg');
    }
    .su-flag-km {                            // Comores
        background-image: url('#{$url-drapeaux}comores.svg');
    }
    .su-flag-cg {                            // Congo
        background-image: url('#{$url-drapeaux}congo.svg');
    }
    .su-flag-kp {                            // Coree-du-nord
        background-image: url('#{$url-drapeaux}coree-du-nord.svg');
    }
    .su-flag-kr {                            // Coree-du-sud
        background-image: url('#{$url-drapeaux}coree-du-sud.svg');
    }
    .su-flag-cr {                            // Costa-rica
        background-image: url('#{$url-drapeaux}costa-rica.svg');
    }
    .su-flag-ci {                            // Cote-d-ivoire
        background-image: url('#{$url-drapeaux}cote-d-ivoire.svg');
    }
    .su-flag-hr {                            // Croatie
        background-image: url('#{$url-drapeaux}croatie.svg');
    }
    .su-flag-cu {                            // Cuba
        background-image: url('#{$url-drapeaux}cuba.svg');
    }
    .su-flag-dk {                            // Danemark
        background-image: url('#{$url-drapeaux}danemark.svg');
    }
    //-------------------------------------------------------------------- 50
    .su-flag-dj {                            // Djibouti
        background-image: url('#{$url-drapeaux}djibouti.svg');
    }
    .su-flag-dm {                            // Dominique
        background-image: url('#{$url-drapeaux}dominique.svg');
    }
    .su-flag-sco {                           // Ecosse
        background-image: url('#{$url-drapeaux}ecosse.svg');
    }
    .su-flag-eg {                            // Egypte
        background-image: url('#{$url-drapeaux}egypte.svg');
    }
    .su-flag-ae {                            // Emirats-arabes-unis
        background-image: url('#{$url-drapeaux}emirats-arabes-unis.svg');
    }
    .su-flag-ec {                            // Equateur
        background-image: url('#{$url-drapeaux}equateur.svg');
    }
    .su-flag-er {                            // Erythree
        background-image: url('#{$url-drapeaux}erythree.svg');
    }
    .su-flag-es {                            // Espagne
        background-image: url('#{$url-drapeaux}espagne.svg');
    }
    .su-flag-ee {                            // Estonie
        background-image: url('#{$url-drapeaux}estonie.svg');
    }
    .su-flag-sz {                            // Eswatini
        background-image: url('#{$url-drapeaux}eswatini.svg');
    }
    //-------------------------------------------------------------------- 60
    .su-flag-va {                            // Etat-de-la-cite-du-vatican
        background-image: url('#{$url-drapeaux}etat-de-la-cite-du-vatican.svg');
    }
    .su-flag-us {                            // Etats-unis
        background-image: url('#{$url-drapeaux}etats-unis.svg');
    }
    .su-flag-et {                            // Ethiopie
        background-image: url('#{$url-drapeaux}ethiopie.svg');
    }
    .su-flag-fj {                            // Fidji
        background-image: url('#{$url-drapeaux}fidji.svg');
    }
    .su-flag-fi {                             // Finlande
        background-image: url('#{$url-drapeaux}finlande.svg');
    }
    .su-flag-fr {                            // France
        background-image: url('#{$url-drapeaux}france.svg');
    }
    .su-flag-ga {                            // Gabon
        background-image: url('#{$url-drapeaux}gabon.svg');
    }
    .su-flag-gm {                            // Gambie
        background-image: url('#{$url-drapeaux}gambie.svg');
    }
    .su-flag-ge {                            // Georgie
        background-image: url('#{$url-drapeaux}georgie.svg');
    }
    .su-flag-gh {                            // Ghana
        background-image: url('#{$url-drapeaux}ghana.svg');
    }
    //-------------------------------------------------------------------- 70
    .su-flag-gr {                            // Grece
        background-image: url('#{$url-drapeaux}grece.svg');
    }
    .su-flag-gd {                            // Grenade
        background-image: url('#{$url-drapeaux}grenade.svg');
    }
    .su-flag-gt {                            // Guatemala
        background-image: url('#{$url-drapeaux}guatemala.svg');
    }
    .su-flag-gw {                            // Guinee-bissau
        background-image: url('#{$url-drapeaux}guinee-bissau.svg');
    }
    .su-flag-gq {                            // Guinee-equatoriale
        background-image: url('#{$url-drapeaux}guinee-equatoriale.svg');
    }
    .su-flag-gn {                            // Guinee
        background-image: url('#{$url-drapeaux}guinee.svg');
    }
    .su-flag-gy {                            // Guyana
        background-image: url('#{$url-drapeaux}guyana.svg');
    }
    .su-flag-ht {                            // Haiti
        background-image: url('#{$url-drapeaux}haiti.svg');
    }
    .su-flag-hn {                            // Honduras
        background-image: url('#{$url-drapeaux}honduras.svg');
    }
    .su-flag-hu {                             // Hongrie
        background-image: url('#{$url-drapeaux}hongrie.svg');
    }
    //-------------------------------------------------------------------- 80
    .su-flag-ck {                             // Iles-cook
        background-image: url('#{$url-drapeaux}iles-cook.svg');
    }
    .su-flag-mh  {                            // Iles-marshall
        background-image: url('#{$url-drapeaux}iles-marshall.svg');
    }
    .su-flag-sb {                             // Iles-salomon
        background-image: url('#{$url-drapeaux}iles-salomon.svg');
    }
    .su-flag-in {                             // Inde
        background-image: url('#{$url-drapeaux}inde.svg');
    }
    .su-flag-id {                             // Indonesie
        background-image: url('#{$url-drapeaux}indonesie.svg');
    }
    .su-flag-iq {                             // Irak
        background-image: url('#{$url-drapeaux}irak.svg');
    }
    .su-flag-ir {                             // Iran
        background-image: url('#{$url-drapeaux}iran.svg');
    }
    .su-flag-ie {                             // Irlande
        background-image: url('#{$url-drapeaux}irlande.svg');
    }
    .su-flag-is {                             // Islande
        background-image: url('#{$url-drapeaux}islande.svg');
    }
    .su-flag-il {                             // Israel
        background-image: url('#{$url-drapeaux}israel.svg');
    }
    //-------------------------------------------------------------------- 90
    .su-flag-it  {                             // Italie
        background-image: url('#{$url-drapeaux}italie.svg');
    }
    .su-flag-jm  {                             // Jamaique
        background-image: url('#{$url-drapeaux}jamaique.svg');
    }
    .su-flag-jp  {                             // Japon
        background-image: url('#{$url-drapeaux}japon.svg');
    }
    .su-flag-jo  {                             // Jordanie
        background-image: url('#{$url-drapeaux}jordanie.svg');
    }
    .su-flag-kz  {                             // Kazakhstan
        background-image: url('#{$url-drapeaux}kazakhstan.svg');
    }
    .su-flag-ke  {                             // Kenya
        background-image: url('#{$url-drapeaux}kenya.svg');
    }
    .su-flag-kg  {                             // Kirghizstan
        background-image: url('#{$url-drapeaux}kirghizstan.svg');
    }
    .su-flag-ki  {                             // Kiribati
        background-image: url('#{$url-drapeaux}kiribati.svg');
    }
    .su-flag-kw  {                             // Koweit
        background-image: url('#{$url-drapeaux}koweit.svg');
    }
    .su-flag-la  {                             // Laos
        background-image: url('#{$url-drapeaux}laos.svg');
    }
    //-------------------------------------------------------------------- 100
    .su-flag-ls  {                             // Lesotho
        background-image: url('#{$url-drapeaux}lesotho.svg');
    }
    .su-flag-lv  {                             // Lettonie
        background-image: url('#{$url-drapeaux}lettonie.svg');
    }
    .su-flag-lb  {                             // Liban
        background-image: url('#{$url-drapeaux}liban.svg');
    }
    .su-flag-lr  {                             // Liberia
        background-image: url('#{$url-drapeaux}liberia.svg');
    }
    .su-flag-ly  {                             // Libye
        background-image: url('#{$url-drapeaux}libye.svg');
    }
    .su-flag-li  {                             // Liechtenstein
        background-image: url('#{$url-drapeaux}liechtenstein.svg');
    }
    .su-flag-lt  {                             // Lituanie
        background-image: url('#{$url-drapeaux}lituanie.svg');
    }
    .su-flag-lu  {                             // Luxembourg
        background-image: url('#{$url-drapeaux}luxembourg.svg');
    }
    .su-flag-mk  {                             // Macedoine-du-nord
        background-image: url('#{$url-drapeaux}macedoine-du-nord.svg');
    }
    .su-flag-mg  {                             // Madagascar
        background-image: url('#{$url-drapeaux}madagascar.svg');
    }
    //-------------------------------------------------------------------- 110
    .su-flag-my {                               // Malaisie
        background-image: url('#{$url-drapeaux}malaisie.svg');
    }
    .su-flag-mw {                               // Malawi
        background-image: url('#{$url-drapeaux}malawi.svg');
    }
    .su-flag-mv {                               // Maldives
        background-image: url('#{$url-drapeaux}maldives.svg');
    }
    .su-flag-ml {                               // Mali
        background-image: url('#{$url-drapeaux}mali.svg');
    }
    .su-flag-mt {                               //Malte
        background-image: url('#{$url-drapeaux}malte.svg');
    }
    .su-flag-ma {                               //Maroc
        background-image: url('#{$url-drapeaux}maroc.svg');
    }
    .su-flag-mu {                               //Maurice
        background-image: url('#{$url-drapeaux}maurice.svg');
    }
    .su-flag-mr {                               //Mauritanie
        background-image: url('#{$url-drapeaux}mauritanie.svg');
    }
    .su-flag-mx {                               //Mexique
        background-image: url('#{$url-drapeaux}mexique.svg');
    }
    .su-flag-fm {                               //Micronesie
        background-image: url('#{$url-drapeaux}micronesie.svg');
    }
    //-------------------------------------------------------------------- 120
    .su-flag-md {                               //Moldavie
        background-image: url('#{$url-drapeaux}moldavie.svg');
    }
    .su-flag-mc {                               //Monaco
        background-image: url('#{$url-drapeaux}monaco.svg');
    }
    .su-flag-mn {                               //Mongolie
        background-image: url('#{$url-drapeaux}mongolie.svg');
    }
    .su-flag-me {                               //Montenegro
        background-image: url('#{$url-drapeaux}montenegro.svg');
    }
    .su-flag-mz {                               //Mozambique
        background-image: url('#{$url-drapeaux}mozambique.svg');
    }
    .su-flag-na {                               //Namibie
        background-image: url('#{$url-drapeaux}namibie.svg');
    }
    .su-flag-nr {                               //Nauru
        background-image: url('#{$url-drapeaux}nauru.svg');
    }
    .su-flag-np {                               //Nepal
        background-image: url('#{$url-drapeaux}nepal.svg');
    }
    .su-flag-ni {                               //Nicaragua
        background-image: url('#{$url-drapeaux}nicaragua.svg');
    }
    .su-flag-ng {                               //Niger
        background-image: url('#{$url-drapeaux}niger.svg');
    }
    //-------------------------------------------------------------------- 130
    .su-flag-ne {                               //Nigeria
        background-image: url('#{$url-drapeaux}nigeria.svg');
    }
    .su-flag-nu {                               //Niue
        background-image: url('#{$url-drapeaux}niue.svg');
    }
    .su-flag-no {                               //Norvege
        background-image: url('#{$url-drapeaux}norvege.svg');
    }
    .su-flag-nz {                               //Nouvelle-zelande
        background-image: url('#{$url-drapeaux}nouvelle-zelande.svg');
    }
    .su-flag-om {                               //Oman
        background-image: url('#{$url-drapeaux}oman.svg');
    }
    .su-flag-ug {                               //Ouganda
        background-image: url('#{$url-drapeaux}ouganda.svg');
    }
    .su-flag-uz {                               //Ouzbekistan
        background-image: url('#{$url-drapeaux}ouzbekistan.svg');
    }
    .su-flag-pk {                               //Pakistan
        background-image: url('#{$url-drapeaux}pakistan.svg');
    }
    .su-flag-pw {                               //Palaos
        background-image: url('#{$url-drapeaux}palaos.svg');
    }
    .su-flag-ps {                               //Palestine
        background-image: url('#{$url-drapeaux}palestine.svg');
    }
    //-------------------------------------------------------------------- 140
    .su-flag-pa {                               //Panama
        background-image: url('#{$url-drapeaux}panama.svg');
    }
    .su-flag-pg {                               //Papouasie-nouvelle-guinee
        background-image: url('#{$url-drapeaux}papouasie-nouvelle-guinee.svg');
    }
    .su-flag-py {                               //Paraguay
        background-image: url('#{$url-drapeaux}paraguay.svg');
    }
    .su-flag-nl {                               //Pays-bas
        background-image: url('#{$url-drapeaux}pays-bas.svg');
    }
    .su-flag-cym {                               //Pays-de-galles
        background-image: url('#{$url-drapeaux}pays-de-galles.svg');
    }
    .su-flag-pe {                               //Perou
        background-image: url('#{$url-drapeaux}perou.svg');
    }
    .su-flag-ph {                               //Philippines
        background-image: url('#{$url-drapeaux}philippines.svg');
    }
    .su-flag-pl {                               //Pologne
        background-image: url('#{$url-drapeaux}pologne.svg');
    }
    .su-flag-pt {                               //Portugal
        background-image: url('#{$url-drapeaux}portugal.svg');
    }
    .su-flag-qa {                               //Qatar
        background-image: url('#{$url-drapeaux}qatar.svg');
    }
    //-------------------------------------------------------------------- 150
    .su-flag-cf {                               //Republique-centreafricaine
        background-image: url('#{$url-drapeaux}republique-centreafricaine.svg');
    }
    .su-flag-cd {                               //Republique-democratique-du-congo
        background-image: url('#{$url-drapeaux}republique-democratique-du-congo.svg');
    }
    .su-flag-do {                               //Republique-dominicaine
        background-image: url('#{$url-drapeaux}republique-dominicaine.svg');
    }
    .su-flag-ro {                               //Roumanie
        background-image: url('#{$url-drapeaux}roumanie.svg');
    }
    .su-flag-gb {                               //Royaume-uni
        background-image: url('#{$url-drapeaux}royaume-uni.svg');
    }
    .su-flag-ru {                               //Russie
        background-image: url('#{$url-drapeaux}russie.svg');
    }
    .su-flag-rw {                               //Rwanda
        background-image: url('#{$url-drapeaux}rwanda.svg');
    }
    .su-flag-kn {                               //Saint-christophe-et-nieves
        background-image: url('#{$url-drapeaux}saint-christophe-et-nieves.svg');
    }
    .su-flag-sm {                               //Saint-marin
        background-image: url('#{$url-drapeaux}saint-marin.svg');
    }
    .su-flag-vc {                               //Saint-vincent-et-les-grenadines
        background-image: url('#{$url-drapeaux}saint-vincent-et-les-grenadines.svg');
    }
    //-------------------------------------------------------------------- 160
    .su-flag-lc {                               //Sainte-lucie
        background-image: url('#{$url-drapeaux}sainte-lucie.svg');
    }
    .su-flag-sv {                               //Salvador
        background-image: url('#{$url-drapeaux}salvador.svg');
    }
    .su-flag-ws {                               //Samoa
        background-image: url('#{$url-drapeaux}samoa.svg');
    }
    .su-flag-st {                               //Sao-tome-et-principe
        background-image: url('#{$url-drapeaux}sao-tome-et-principe.svg');
    }
    .su-flag-sn {                               //Senegal
        background-image: url('#{$url-drapeaux}senegal.svg');
    }
    .su-flag-rs {                               //Serbie
        background-image: url('#{$url-drapeaux}serbie.svg');
    }
    .su-flag-sc {                               //Seychelles
        background-image: url('#{$url-drapeaux}seychelles.svg');
    }
    .su-flag-sl {                               //Sierra-leone
        background-image: url('#{$url-drapeaux}sierra-leone.svg');
    }
    .su-flag-sg {                               //Singapour
        background-image: url('#{$url-drapeaux}singapour.svg');
    }
    .su-flag-sk {                               //Slovaquie
        background-image: url('#{$url-drapeaux}slovaquie.svg');
    }
    //-------------------------------------------------------------------- 170
    .su-flag-si {                               //Slovenie
        background-image: url('#{$url-drapeaux}slovenie.svg');
    }
    .su-flag-so {                               //Somalie
        background-image: url('#{$url-drapeaux}somalie.svg');
    }
    .su-flag-ss {                               //Soudan-du-sud
        background-image: url('#{$url-drapeaux}soudan-du-sud.svg');
    }
    .su-flag-sd {                               //Soudan
        background-image: url('#{$url-drapeaux}soudan.svg');
    }
    .su-flag-lk {                               //Sri-lanka
        background-image: url('#{$url-drapeaux}sri-lanka.svg');
    }
    .su-flag-se {                               //Suede
        background-image: url('#{$url-drapeaux}suede.svg');
    }
    .su-flag-ch {                               //Suisse
        background-image: url('#{$url-drapeaux}suisse.svg');
    }
    .su-flag-sr {                               //Suriname
        background-image: url('#{$url-drapeaux}suriname.svg');
    }
    .su-flag-sy {                               //Syrie
        background-image: url('#{$url-drapeaux}syrie.svg');
    }
    .su-flag-tj {                               //Tadjikistan
        background-image: url('#{$url-drapeaux}tadjikistan.svg');
    }
    //-------------------------------------------------------------------- 180
    .su-flag-tz {                               //Tanzanie
        background-image: url('#{$url-drapeaux}tanzanie.svg');
    }
    .su-flag-td {                               //Tchad
        background-image: url('#{$url-drapeaux}tchad.svg');
    }
    .su-flag-cz {                               //Tchequie
        background-image: url('#{$url-drapeaux}tchequie.svg');
    }
    .su-flag-th {                               //Thailande
        background-image: url('#{$url-drapeaux}thailande.svg');
    }
    .su-flag-tl {                               //Timor-oriental
        background-image: url('#{$url-drapeaux}timor-oriental.svg');
    }
    .su-flag-tg {                               //Togo
        background-image: url('#{$url-drapeaux}togo.svg');
    }
    .su-flag-to {                               //Tonga
        background-image: url('#{$url-drapeaux}tonga.svg');
    }
    .su-flag-tt {                               //Trinite-et-tobago
        background-image: url('#{$url-drapeaux}trinite-et-tobago.svg');
    }
    .su-flag-tn {                               //Tunisie
        background-image: url('#{$url-drapeaux}tunisie.svg');
    }
    .su-flag-tm {                               //Turkmenistan
        background-image: url('#{$url-drapeaux}turkmenistan.svg');
    }
    //-------------------------------------------------------------------- 190
    .su-flag-tr {                               //Turquie
        background-image: url('#{$url-drapeaux}turquie.svg');
    }
    .su-flag-tv {                               //Tuvalu
        background-image: url('#{$url-drapeaux}tuvalu.svg');
    }
    .su-flag-ua {                               //Ukraine
        background-image: url('#{$url-drapeaux}ukraine.svg');
    }
    .su-flag-uy {                               //Uruguay
        background-image: url('#{$url-drapeaux}uruguay.svg');
    }
    .su-flag-vu {                               //Vanuatu
        background-image: url('#{$url-drapeaux}vanuatu.svg');
    }
    .su-flag-ve {                               //Venezuela
        background-image: url('#{$url-drapeaux}venezuela.svg');
    }
    .su-flag-vn {                               //Viet-nam
        background-image: url('#{$url-drapeaux}viet-nam.svg');
    }
    .su-flag-ye {                               //Yemen
        background-image: url('#{$url-drapeaux}yemen.svg');
    }
    .su-flag-zm {                               //Zambie
        background-image: url('#{$url-drapeaux}zambie.svg');
    }
    .su-flag-zw {                               //Zimbabwe
        background-image: url('#{$url-drapeaux}zimbabwe.svg');
    }
    //-------------------------------------------------------------------- 200
}
