/*
    Styles du composant [steppers] de SipaUI
*/
@import "variables";

.sipaui {
    .su-carrousel {
        position: relative;
        height: auto;
        width: 100%;
        
        .su-carrousel-container {
            display: flex;
            flex-wrap: nowrap;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            scroll-padding: 1rem;
            scrollbar-width: none;
            width: 100%;
            
            .su-carrousel-item {
                margin: 0;
            }
        }
        .su-carrousel-container::-webkit-scrollbar {
            display: none;
        }
        
        &[class*="su-carrousel-items-"] {
            .su-carrousel-item {
                flex: 1 0 auto;
            }
        }
        &.su-carrousel-items-xs {
            .su-carrousel-item {
                width: $width-carrousel-item-xs;
            }
        }
        &.su-carrousel-items-s {
            .su-carrousel-item {
                width: $width-carrousel-item-s;
            }
        }
        &.su-carrousel-items-m {
            .su-carrousel-item {
                width: $width-carrousel-item-m;
            }
        }
        &.su-carrousel-items-l {
            .su-carrousel-item {
                width: $width-carrousel-item-l;
            }
        }
        &.su-carrousel-items-xl {
            .su-carrousel-item {
                width: $width-carrousel-item-xl;
            }
        }
        
        > [class*="su-button-"] {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 1;
                background: $background-carrousel-buttons;
                width: $width-carrousel-buttons;
                height: $height-carrousel-buttons;
                border-radius: $border-radius-carrousel-buttons;
                box-shadow: $box-shadow-carrousel-buttons;
            .su-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -52%);
                color: $color-carrousel-buttons-icon;
            }
            &:hover {
                background: $background-carrousel-buttons-hover;
                
                & .su-icon {
                    color: $color-carrousel-buttons-icon-hover;
                    opacity: $opacity-carrousel-buttons-icon-hover;
                }
            }
        }
        .su-carrousel-container:not([data-suswipe*='"rambo":true']) ~ [class*="su-button-"].su-inactive {
            display: none;
        }
        
        .su-button-right {
            right: 0;
                border-radius: $border-radius-carrousel-button-right;
                box-shadow: $box-shadow-carrousel-button-right;
        }
    }
    .su-carrousel-container { // cette déclaration est sortie de .carrousel afin qu’elle soit plus facilement surchargeable.
        gap: 20px;
    }
}
.su-touch {
    .su-carrousel {
        .su-carrousel-container {
            -webkit-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
            
            .su-carrousel-item {
                scroll-snap-align: center
            }
            .su-carrousel-item:first-of-type {
                scroll-snap-align: start
            }
            .su-carrousel-item:last-of-type {
                scroll-snap-align: end
            }
        }
        [class*="su-button-"] { //surcharge la déclaration @media (hover: none) ci-dessous en fallback
            display: none;
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui .su-carrousel.su-carrousel-grid {
        &[class*="su-carrousel-items-"] {
            .su-carrousel-item {
                width: inherit;
            }
        }
    }
}

@media (hover: none) {
    .sipaui .su-carrousel {
        [class*="su-button-"] {
            display: none;
        }
    }
}
















