/*
    Styles du composant [Author] de SipaUI
*/
@import "variables";


// AUTEUR GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-author,
    .su-author b,
    .su-author strong,
    .su-author i,
    .su-author em {
        font-family: $font-family-author;
        font-size: $font-size-author;
        font-weight: $font-weight-author;
        line-height: $line-height-author;
        color: $color-author;
        font-style: normal;
    }
    .su-author a,
    a.su-author {
        color: $color-link-article;
        
        &:hover {
            color: $color-link-article-hover;
        }
    }
}
