/*
    Styles du composant [input date] de SipaUI
*/

@import "variables";

.sipaui {
    .su-input-date {
        position: relative;
        
        [type="date"] {
            cursor: pointer;
            padding: $padding-input-date;
            @include appearance(none);
            
            &::-webkit-calendar-picker-indicator {
                position: absolute;
                right: 0;
                top: 0;
                width: var(--taille-tactile);
                height: 100%;
                padding: 0;
                cursor: pointer;
                opacity: 0;
                @include appearance(none);
                z-index: 2;
            }
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button,
            &::-webkit-clear-button {
                display: none;
                @include appearance(none);
                margin: 0;
            }
            &::-webkit-datetime-edit-fields-wrapper,
            &::-webkit-date-and-time-value {
                text-align: left;
            }
        }
        &::after {
            content: "calendrier";
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: $top-input-date-icon;
            right: $right-input-date-icon;
            width: $size-input-date-icon;
            height: $size-input-date-icon;
            font-family: su-icons;
            font-size: $font-size-input-date-icon;
            color: $color-picto;
            pointer-events: none;
        }
        &:not(.su-disabled):hover::after {
            color: $color-picto-hover;
        }
        &.su-input-date-width {
            width: $width-input-date;
        }
        &.su-disabled [readonly] {
            opacity: 1;
        }
    }
}

@-moz-document url-prefix() { // Hack pour Firefox
    .sipaui {
       .su-input-date {
           [type="date"] {
               padding-right: 5px;
           }
           &::after {
               content: "";
           }
       }
    }
}
