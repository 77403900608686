/*
    Styles du composant [pop-ins] de SipaUI
*/
@import "variables";

// Animation d’ouverture
@keyframes animation-pop-in {
  0%{
    opacity:0;
    transform: scale(0);
  }
  100%{
    opacity:1;
    transform: scale(1);
  }
}

.sipaui {
    .su-close {
        width: var(--taille-tactile);
        height: var(--taille-tactile);
        cursor: pointer;
        padding: 0;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include hover-active-focus {
            opacity: $opacity-hover;
        }
    }
    .su-pop-in { // styles visuels des pop-in
        width: 100%;
        max-height: calc(100vh - var(--marge-m) * 2);
        max-width: calc(100vw - var(--marge-m) * 2);
        padding: $padding-popin;
        overflow: hidden;
        flex-direction: column;
        background: $background-pop-in;
        transition: $transition-pop-in;
        border: $border-pop-in;
        border-radius: $border-radius-pop-in;

        &.su-pop-in-in {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
        &.su-pop-in-out {
            opacity: 0;
            transform: translate(-50%, -50%) scale(0);
            transition: $transition-pop-out;
        }
        .su-close-area {
            min-height: $min-height-close-area;
        }
        .su-close {
            position: absolute;
            top: var(--marge-xs);
            right: var(--marge-xs);
        }
        .su-content-area {
            overflow-y: auto;
        }
        p {
            margin-bottom: var(--marge-s);
        }
        p:last-child {
            margin-bottom: 0;
        }
        .su-buttons-area {
            margin-top: var(--marge-m);
            display: flex;
            flex: 1 0;
            overflow: hidden;
            min-height: var(--taille-tactile); // pour contrer un bug Safari : cet élément n’a pas de hauteur quand il est dans un <dialog>
        }
    }
    div.su-pop-in { // Pop-in en DIV
        opacity: 0;
        display: flex;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        z-index: 10300;
    }
    dialog.su-pop-in { // Pop-in en DIALOG (transition impossible à cause du display: none mis par les navigateurs sur les dialog non-ouverts.)
        opacity: 0;
        
        &[open] {
            display: flex;
            animation: $animation-pop-in-dialog;
        }
        &::backdrop {
            background: $backdrop-color-dialog;
            opacity: $backdrop-opacity-dialog;
        }
    }
}
// CSS permettant de bloquer le scroll quand la popin est ouverte, mais cela pourrait représenter un risque au niveau des perf…
// body:has(dialog.su-pop-in[open]) {
body:has(dialog.su-no-body-scroll[open]) {
    overflow: hidden;
}

@media screen and (min-width: $min-breakpoint-sm) {
	.sipaui {
        .su-pop-in {
            max-width: calc(100vw - var(--marge-l) * 2);
        }
    }
}
@media screen and (max-width: $max-breakpoint-sm) {
	.sipaui {
        .su-pop-in {
            .su-buttons-area {
                flex-direction: column;
                align-items: stretch;
                min-height: calc(var(--taille-tactile) * 2 + var(--marge-m)); // pour contrer un bug Safari : cet élément n’a pas de hauteur quand il est dans un <dialog>
            }
            .su-primary {
                order: 1;
            }
            .su-secondary {
                order: 2;
                margin-top: var(--marge-m);
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
	.sipaui {
        .su-pop-in {
            max-width: 620px;
            padding-left: $padding-popin-desktop;
            padding-right: $padding-popin-desktop;

            .su-buttons-area {
                justify-content: flex-end
            }
            .su-secondary {
                margin-right: var(--marge-m);
            }
        }
    }
}
