/*
    Styles du composant [Notifications] de SipaUI
*/
@import "variables";

// Animation d’ouverture
@keyframes animation-notification {
  0%{
    bottom: $bottom-notification-debut;
  }
  100%{
    bottom: $bottom-notification-final;
  }
}

.sipaui {
    .su-notification {
        flex-direction: column;
        position: fixed;
        overflow: hidden;
        top: $top-notification;
        right: $right-notification;
        bottom: $bottom-notification-debut;
        left: $left-notification;
        width: $width-notification;
        max-width: $max-width-notification;
        max-height: $max-height-notification;
        backdrop-filter: $backdrop-filter-notification;
        background: $background-notification;
        border: $border-notification;
        border-radius: $border-radius-notification;
        padding: $padding-notification;
        color: $color-texte-notification;
        text-align: $text-align-texte-notification;
        //z-index: 10400;
        
        * {
            color: $color-texte-notification;
        }
        > :first-child {
            margin-top: 0;
        }
        > :last-child {
            margin-bottom: 0;
        }
        &[open] {
            display: inline-flex;
            animation: $animation-notification;
        }
    }
}
