/*
    Styles du composant [media-icons] de SipaUI
*/
@import "variables";


// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    caption,
    figcaption {
        text-align: $text-align-caption;
        color: $color-caption;
        font-size: $font-size-caption;
        font-style: $font-style-caption;
        font-weight: $font-weight-caption;
        padding: $padding-caption;
        
        .su-credit {
            text-transform: $text-transform-caption-credit;
            color: $color-caption-credit;
            font-style: $font-style-caption-credit;
            font-weight: $font-weight-caption-credit;
        }
    }
    .su-viewer {
        figcaption {
            text-align: $text-align-caption-viewer;
            color: $color-caption-viewer;
            font-size: $font-size-caption-viewer;
            padding: $padding-caption-viewer;
            
            .su-credit {
                color: $color-caption-viewer-credit;
                font-style: $font-style-caption-viewer-credit;
                font-weight: $font-weight-caption-viewer-credit;
            }
        }
    }
}
