/*
    Styles du composant [picture] de SipaUI
*/
@import "variables";

// CŒUR ARTICLE --------------------------------------------------------------------------------------/
.sipaui {
    .su-article {
        figure {
            margin: $margin-figure-article;
            
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &> .su-media,
            &> img,
            &> picture {
                width: 100%;
            }
            &> img,
            &> picture {
                box-sizing: border-box;
                border-radius: $border-radius-img-article;
                border: $border-img-article;
                box-shadow: $box-shadow-img-article;
            }
        }
    }
}

@media screen and (max-width: $max-breakpoint-sm) {
    .sipaui {
        .su-article {
            figure.su-edge-mobile {
                margin-left: calc(#{$marge-container-sm} * -1);
                margin-right: calc(#{$marge-container-sm} * -1);
            }
        }
    }
}

@media screen and (max-width: $max-breakpoint-xs) {
    .sipaui {
        .su-article {
            figure.su-edge-mobile {
                margin-left: calc(#{$marge-container-xs} * -1);
                margin-right: calc(#{$marge-container-xs} * -1);
            }
        }
    }
}

// MAIN PICTURE --------------------------------------------------------------------------------------/
.sipaui {
    .su-media-container {
        position: relative;
    }
}

// MAIN PICTURE --------------------------------------------------------------------------------------/
.sipaui {
    .su-main-picture {
        box-sizing: border-box;
        border-radius: $border-radius-img-main-picture;
        border-width: $border-width-img-main-picture;
        border-color: $border-color-img-main-picture;
        border-style: $border-style-img-main-picture;
        box-shadow: $box-shadow-img-main-picture;
        position: relative;
        overflow: hidden;
        width: fit-content;
    }
}

// RATIOS --------------------------------------------------------------------------------------/
.sipaui {
    [class*="su-ratio-"] {
        position: relative;
        overflow: hidden;
        width: auto; //sert à contrer le width: fit-content de su-main-picture
        
        & .su-media {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            min-height: 100%;
        }
    }
        
    .su-ratio-1-1 {
        padding-top: 100%;
        
        & .su-media {
            max-width: none;
            height: 100%;
            width: auto;
        }
    }
    .su-ratio-16-9 {
        padding-top: calc(56.25% - (#{$border-width-img-main-picture} * 2));
    }
    .su-ratio-16-7 {
        padding-top: calc(43.75% - (#{$border-width-img-main-picture} * 2));
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-ratio-16-9-md {
            padding-top: calc(56.25% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-16-7-md {
            padding-top: calc(43.75% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-1-1-md {
            padding-top: 100%;
            
            & .su-media {
                max-width: none;
                height: 100%;
                width: auto;
            }
        }
        .su-ratio-16-9-md,
        .su-ratio-16-7-md {
            & .su-media {
                max-width: 100%;
                height: auto;
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-ratio-16-9-lg {
            padding-top: calc(56.25% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-16-7-lg {
            padding-top: calc(43.75% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-1-1-lg {
            padding-top: 100%;
            
            & .su-media {
                max-width: none;
                height: 100%;
                width: auto;
            }
        }
        .su-ratio-16-9-lg,
        .su-ratio-16-7-lg {
            & .su-media {
                max-width: 100%;
                height: auto;
                width: 100%;
            }
        }
    }
}

// RADIUS OPTIONNEL --------------------------------------------------------------------------------------/
.sipaui {
    .su-radius-on,
    figure > .su-radius-on,
    .su-main-picture.su-radius-on {
        border-radius: $border-radius-on !important;
        overflow: hidden;
    }
    .su-radius-off,
    figure > .su-radius-off,
    .su-main-picture.su-radius-off {
        border-radius: $border-radius-off !important;
    }
}
