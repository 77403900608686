/*
    Styles du composant [teaser] de SipaUI
*/
@import "variables";

// TEASERS
.sipaui {
    .su-teaser,
    .su-teaser-big,
    .su-teaser-text {
        display: grid;
        gap: $gap-teaser;
        
        .su-media-container,
        article > figure { // a supprimer en v7 (su-media-container obligatoire)
            width: $width-figure-teaser;
        }
        .su-standfirst {
            display: none;
        }
    }
}


// TEASER STANDARD
.sipaui {
    .su-teaser,
    .su-teaser-big {
        grid-template-columns: $grid-template-columns-teaser;
        grid-template-rows: $grid-template-rows-teaser;
        
        .su-title {
            grid-area: $grid-area-titre-teaser-sans-image;
        }
        .su-metadata {
            grid-area: $grid-area-metadata-teaser-sans-image;
        }
        .su-media-container,
        article > figure {
            grid-area: $grid-area-figure-teaser;
        }
        .su-media-container ~ .su-title,
        figure ~ .su-title { // a supprimer en v7 (su-media-container obligatoire)
            grid-area: $grid-area-titre-teaser;
        }
        .su-media-container ~ .su-metadata,
        figure ~ .su-metadata { // a supprimer en v7 (su-media-container obligatoire)
            grid-area: $grid-area-metadata-teaser;
        }
        .su-media-container:has(.su-visually-hidden),
        figure:has(.su-visually-hidden) { // a supprimer en v7 (su-media-container obligatoire)
            display: none;
            
            ~ .su-title {
                grid-area: $grid-area-titre-teaser-sans-image;
            }
            ~ .su-metadata {
                grid-area: $grid-area-metadata-teaser-sans-image;
            }
        }
    }
}

// TEASER SPONSORISÉ
.sipaui {
    .su-articles-area:not(.su-sponsored-articles) {
        .su-teaser,
        .su-teaser-big {
            &.su-sponsored {
                padding-top: 36px !important;
                
                &::before {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                }
            }
        }
    }
}

// GRAND TEASER
@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-teaser-big {
            grid-template-columns: $grid-template-columns-grand-teaser;
            grid-template-rows: $grid-template-rows-grand-teaser; 
            column-gap: $column-gap-grand-teaser;
            
            .su-title {
                font-size: $font-size-titre-grand-teaser;
                line-height: $line-height-titre-grand-teaser;
                grid-area: $grid-area-titre-grand-teaser-sans-image;
            }
            .su-standfirst {
                display: block;
                overflow: hidden;
                margin: $margin-chapeau-grand-teaser;
                grid-area: $grid-area-chapeau-grand-teaser-sans-image;
                @supports (display: -webkit-box) {
                    display: -webkit-box;
                    -webkit-line-clamp: $line-clamp-chapeau-grand-teaser;
                    -webkit-box-orient: vertical;
                }
            }
            .su-metadata {
                grid-area: $grid-area-metadata-grand-teaser-sans-image;
            }
            .su-media-container,
            figure { // a supprimer en v7 (su-media-container obligatoire)
                grid-area: $grid-area-figure-grand-teaser;
                width: $width-figure-grand-teaser;
            }
            .su-media-container ~ .su-title,
            figure ~ .su-title { // a supprimer en v7 (su-media-container obligatoire)
                grid-area: $grid-area-titre-grand-teaser;
            }
            .su-media-container ~ .su-standfirst,
            figure ~ .su-standfirst { // a supprimer en v7 (su-media-container obligatoire)
                grid-area: $grid-area-chapeau-grand-teaser;
            }
            .su-media-container ~ .su-metadata,
            figure ~ .su-metadata { // a supprimer en v7 (su-media-container obligatoire)
                grid-area: $grid-area-metadata-grand-teaser;
            }
            .su-media-container:has(.su-visually-hidden),
            figure:has(.su-visually-hidden) { // a supprimer en v7 (su-media-container obligatoire)
                ~ .su-standfirst {
                    grid-area: $grid-area-chapeau-grand-teaser-sans-image;
                }
                ~ .su-metadata {
                    grid-area: $grid-area-metadata-grand-teaser-sans-image;
                }
            }
        }
    }
}
