/*
    Styles du composant [Tables] de SipaUI
*/
@import "variables";


// TABLEAU BASE-----------------------------------------------------------------------------------------/
.sipaui {
    table,
    .su-data-table,
    .su-article table,
    .su-article .su-data-table {
        display: table;
        width: fit-content;
        margin: 0;
        
        &,
        tr,
        tr:nth-child(2n),
        th,
        td {
            border: none;
            border-collapse: collapse;
            border-spacing: 0;
            background: transparent;
            font-size: $font-size-table;
        }
        tr {
            height: var(--taille-tactile);
        }
        th {
            vertical-align: middle;
            padding: $padding-th-td;
        }
        td {
            padding: $padding-th-td;
        }
        &.su-no-ending-border {
            >:last-child {
                tr:last-child {
                    border-bottom: none !important;
                    
                    th,
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

// TABLEAU GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-article table:not(.su-data-table) {
        &,
        tr,
        tr:nth-child(2n),
        th,
        td {
            border-collapse: collapse; // Si on veut gérer des arrondis sur le tableau, il faut passer en "separate", mais cela pose des pb ailleurs : on perd les styles de bordure sur les tr, thead, tbody et tfoot et il faut passer à des bordures sur les th et td ! -> pour l’instant, les tableaux à coins arrondis sont interdits
        }
        & {
            border: $border-table-gen;
            background: $background-table-gen;
            margin: $margin-table-gen;
        }
        tr,
        tr:nth-child(2n) {
            background: $background-tr-gen;
        }
        th {
            border-top: $border-top-th-gen;
            border-right: $border-right-th-gen;
            border-bottom: $border-bottom-th-gen;
            border-left: $border-left-th-gen;
            background: $background-th-gen;
            color: $color-th-gen;
            font-size: $font-size-th-gen;
            font-weight: $font-weight-th-gen;
        }
        thead th {
            background: $background-thead-th-gen;
            color: $color-thead-th-gen;
            font-size: $font-size-thead-th-gen;
            font-weight: $font-weight-thead-th-gen;
        }
        td {
            border-top: $border-top-td-gen;
            border-right: $border-right-td-gen;
            border-bottom: $border-bottom-td-gen;
            border-left: $border-left-td-gen;
            background: $background-td-gen;
        }
    }
}

// TABLEAU SPÉCIFIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-article .su-data-table,
    .su-data-table {
        & {
            border: $border-table-spe;
            background: $background-table-spe;
        }
        &.su-table-outside-borders {
            border: $border-table-outside-borders;
            
            &.su-table-inside-borders {
                border: none;
                
                th,
                td {
                    border: $border-table-inside-borders;
                }
                thead th,
                thead td {
                    border-bottom: none;
                }
            }
            &.su-table-vertical-inside-borders {
                th,
                td {
                    border-right: $border-table-inside-borders;
                }
            }
            &.su-table-horizontal-inside-borders {
                th,
                td {
                    border-bottom: $border-table-inside-borders;
                }
            }
        }
        &.su-table-no-outside-borders {
            border: none;
        }
        &.su-table-no-inside-borders {
            th,
            td {
                border: none;
            }
        }
        &.su-table-no-vertical-inside-borders {
            th,
            td {
                border-right: none;
            }
        }
        &.su-table-no-horizontal-inside-borders {
            th,
            td {
                border-bottom: none;
            }
        }
        
        tr,
        tr:nth-child(2n) {
            background: $background-tr-spe;
            height: var(--taille-tactile);
        }
        th {
            border-top: $border-top-th-spe;
            border-right: $border-right-th-spe;
            border-bottom: $border-bottom-th-spe;
            border-left: $border-left-th-spe;
            background: $background-th-spe;
            color: $color-th-spe;
            font-size: $font-size-th-spe;
            font-weight: $font-weight-th-spe;
        }
        thead th {
            background: $background-thead-th-spe;
            color: $color-thead-th-spe;
            font-size: $font-size-thead-th-spe;
            font-weight: $font-weight-thead-th-spe;
        }
        td {
            border-top: $border-top-td-spe;
            border-right: $border-right-td-spe;
            border-bottom: $border-bottom-td-spe;
            border-left: $border-left-td-spe;
            background: $background-td-spe;
        }
        
        &.su-table-even-row {
            tbody tr:nth-child(2n) {
                background: $background-tr-even-spe;
            }
        }
    } 
}

// TABLEAU NÉGATIF-----------------------------------------------------------------------------------------/
.sipaui {
    .su-negative .su-article table:not(.su-data-table),
    .su-negative .su-article .su-data-table,
    .su-negative .su-data-table,
    .su-article table.su-negative:not(.su-data-table),
    .su-article .su-data-table.su-negative,
    .su-data-table.su-negative {
        &,
        tr,
        th,
        td,
        caption {
            background: transparent;
            color: $color-all-negative;
            border-color: $border-color-all-negative;
        }
    }
    .su-negative .su-article .su-data-table,
    .su-negative .su-data-table,
    .su-article .su-data-table.su-negative,
    .su-data-table.su-negative {
        &.su-table-even-row {
            tbody tr:nth-child(2n) {
                background: $background-tr-even-spe-negative;
            }
        }
    }
}
// LARGEUR DE COLONNES-----------------------------------------------------------------------------------------/
.sipaui {
    .su-table-col-width-5 {
        width: 5%;
    }
    .su-table-col-width-10 {
        width: 10%;
    }
    .su-table-col-width-15 {
        width: 15%;
    }
    .su-table-col-width-20 {
        width: 20%;
    }
    .su-table-col-width-25 {
        width: 25%;
    }
    .su-table-col-width-30 {
        width: 30%;
    }
    .su-table-col-width-35 {
        width: 35%;
    }
    .su-table-col-width-40 {
        width: 40%;
    }
    .su-table-col-width-45 {
        width: 45%;
    }
    .su-table-col-width-50 {
        width: 50%;
    }
    .su-table-col-width-55 {
        width: 55%;
    }
    .su-table-col-width-60 {
        width: 60%;
    }
    .su-table-col-width-65 {
        width: 65%;
    }
    .su-table-col-width-70 {
        width: 70%;
    }
    .su-table-col-width-75 {
        width: 75%;
    }
    .su-table-col-width-80 {
        width: 80%;
    }
    .su-table-co-85 {
        width: 85%;
    }
    .su-table-col-width-90 {
        width: 90%;
    }
    .su-table-col-width-95 {
        width: 95%;
    }
}

.sipaui {
    .su-responsive-data-table {
        width: 100%;
        overflow-x: auto;
    }
}
