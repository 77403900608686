/*
    Styles du composant [buttons] de SipaUI
*/

@import "variables";
$transition-button: .2s ease;

.sipaui {
    .su-button {
        cursor: pointer;
        font-family: $font-family-button;
        font-size: $font-size-button;
        font-style: normal;
        text-align: center;
        text-decoration: none !important;
        display: inline-block;
        margin: 0;
        padding: 0 $side-padding-button;
        min-width: 120px;
        -webkit-user-select: none;
        -moz-user-select:    none;
        -ms-user-select:     none;
        user-select:         none;
        -webkit-tap-highlight-color: transparent;
        transition: background $transition-button,
                    color      $transition-button,
                    box-shadow $transition-button,
                    opacity    $transition-button;
        height: $height-button;
        line-height: $height-button;
        text-shadow: none;
        white-space: nowrap;
        //CSS pour bloquer le contenu trop long dans un tag
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        vertical-align: top; //empêche une "fausse marge" basse provoquée par l’overflow

        &.su-small {
            font-size: $font-size-button-small;
            padding: 0 $side-padding-button-small;
            height: $height-button-small;
            line-height: $height-button-small;
            min-width: 100px;
            
            &.su-illustration-icon {
               padding-left: calc($side-padding-button-small - 4px);
            }
            &.su-action-icon {
               padding-right: calc($side-padding-button-small - 4px);
            }
        }

        // PRIMARY ------------------------------------------------------------------------------------------/
        &.su-primary,
        &.su-primary:visited {
            background: $background-button-primary;
            color: $color-button-primary;
            text-transform: $text-transform-button-primary;
            border: $border-button-primary;
            font-weight: $font-weight-button-primary;
            border-radius: $radius-button-primary;
            box-shadow: $shadow-button-primary;
        }
        
        &.su-primary {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                background: $background-button-primary-hover;
                color: $color-button-primary-hover;
                border: $border-button-primary-hover;
                box-shadow: $shadow-button-primary-hover;
                opacity: $opacity-button-primary-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                background:$background-button-primary-active;
                color: $color-button-primary-active;
                border: $border-button-primary-active;
                box-shadow: $shadow-button-primary-active;
                opacity: $opacity-button-primary-active;
            }
                                                                            // &:focus:not(.su-disabled):not([disabled]) {
                                                                            //     background: $background-button-primary;
                                                                            //     color: $color-button-primary;
                                                                            //     border: $border-button-primary;
                                                                            //     box-shadow: $shadow-button-primary;
                                                                            //     opacity: 1;
                                                                            //     box-shadow: 0 0 0 4px rgba($background-button-primary, .4);
                                                                            // }
        }

        // SECONDARY ------------------------------------------------------------------------------------------/
        &.su-secondary,
        &.su-secondary:visited {
            background: $background-button-secondary;
            color: $color-button-secondary;
            text-transform: $text-transform-button-secondary;
            border: $border-button-secondary;
            font-weight: $font-weight-button-secondary;
            border-radius: $radius-button-secondary;
            box-shadow: $shadow-button-secondary;
        }
        
        &.su-secondary {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                background: $background-button-secondary-hover;
                color: $color-button-secondary-hover;
                border: $border-button-secondary-hover;
                box-shadow: $shadow-button-secondary-hover;
                opacity: $opacity-button-secondary-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                background:$background-button-secondary-active;
                color: $color-button-secondary-active;
                border: $border-button-secondary-active;
                box-shadow: $shadow-button-secondary-active;
                opacity: $opacity-button-secondary-active;
            }
        }

        // SUBSCRIPTION ------------------------------------------------------------------------------------------/
        &.su-subscription,
        &.su-subscription:visited {
            background: $background-button-subscription;
            color: $color-button-subscription;
            text-transform: $text-transform-button-subscription;
            border: $border-button-subscription;
            font-weight: $font-weight-button-subscription;
            border-radius: $radius-button-subscription;
            box-shadow: $shadow-button-subscription;
        }

        &.su-subscription {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                background: $background-button-subscription-hover;
                color: $color-button-subscription-hover;
                border: $border-button-subscription-hover;
                box-shadow: $shadow-button-subscription-hover;
                opacity: $opacity-button-subscription-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                background:$background-button-subscription-active;
                color: $color-button-subscription-active;
                border: $border-button-subscription-active;
                box-shadow: $shadow-button-subscription-active;
                opacity: $opacity-button-subscription-active;
            }
        }
        
        // ICONES ------------------------------------------------------------------------------------------/
        .su-icon,
        svg {
            display: none;
        }
        svg {
            max-height: 24px;
        }
        &.su-illustration-icon,
        &.su-action-icon {
            .su-icon,
            svg {
                display: inline-block;
                vertical-align: middle;
                transform: translateY(-2px);
            }
        }
        &.su-illustration-icon {
                padding-left: calc($side-padding-button - 4px);
            
            svg {
                margin-right: 4px;
            }
        }
        &.su-action-icon {
                padding-right: calc($side-padding-button - 4px);
            
            svg {
                margin-left: 4px;
            }
        }
        
        &:not(.su-stroke) {
            svg,
            svg path {
                // fill: $color-button-primary !important;
                fill: currentColor !important;
                stroke: none !important;
            }
        }
        &.su-stroke {
            svg,
            svg path {
                // fill: $color-button-primary !important;
                fill: none !important;
                stroke: currentColor !important;
            }
        }
    }

    @if $do-not-use-negative == null {

        // NÉGATIF
        .su-negative .su-button.su-primary,
        .su-button.su-primary.su-negative {
            background: $background-button-primary-negative;
            color: $color-button-primary-negative;
            border: $border-button-primary-negative;
            box-shadow: $shadow-button-primary-negative;

            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                background: $background-button-primary-negative-hover;
                color: $color-button-primary-negative-hover;
                border: $border-button-primary-negative-hover;
                box-shadow: $shadow-button-primary-negative-hover;
                opacity: $opacity-button-primary-negative-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                background:$background-button-primary-negative-active;
                color: $color-button-primary-negative-active;
                border: $border-button-primary-negative-active;
                box-shadow: $shadow-button-primary-negative-active;
                opacity: $opacity-button-primary-negative-active;
            }
        }
        .su-negative .su-button.su-secondary,
        .su-button.su-secondary.su-negative {
            background: $background-button-secondary-negative;
            color: $color-button-secondary-negative;
            border: $border-button-secondary-negative;
            box-shadow: $shadow-button-secondary-negative;
            
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                background: $background-button-secondary-negative-hover;
                color: $color-button-secondary-negative-hover;
                border: $border-button-secondary-negative-hover;
                box-shadow: $shadow-button-secondary-negative-hover;
                opacity: $opacity-button-secondary-negative-hover;
            }
            
            &:active:not(.su-disabled):not([disabled]) {
                background:$background-button-secondary-negative-active;
                color: $color-button-secondary-negative-active;
                border: $border-button-secondary-negative-active;
                box-shadow: $shadow-button-secondary-negative-active;
                opacity: $opacity-button-secondary-negative-active;
            }
        }
    }
}
