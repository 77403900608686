@import "palette";

// COULEURS ----------------------------------------------------------------------------------
:root {
    // PALETTE CHARTE
    --blanc:                    #{$blanc};
    --gris-plus-clair:          #{$gris-plus-clair};
    --gris-clair:               #{$gris-clair};
    --gris:                     #{$gris};
    --gris-fonce:               #{$gris-fonce};
    --gris-plus-fonce:          #{$gris-plus-fonce};
    --anthracite:               #{$anthracite};
    --noir:                     #{$noir};
    // Abonnement
    --jaune:                    #{$jaune};
    --jaune-2:                  #{$jaune-2};
    // Avertissements
    --rouge:                    #{$rouge};
    --vert:                     #{$vert};
    
    // PALETTE SÉMANTIQUE
    --couleur-abo-1:            #{$jaune};
    --couleur-abo-2:            #{$jaune-2};
    
    --couleur-sponso-1:         #{$gris-clair};   // Articles sponsorisés (Communication)
    
    // MARQUES SIPA
    --couleur-of-1:             #{$rouge};          // Ouest-france
    --couleur-of-2:             #{$rouge-2};
    --couleur-co-1:             #{$rouge};          // Courrier de l’Ouest
    --couleur-co-2:             #{$rouge-2};
    --couleur-po-1:             #{$bleu};           // Presse Océan
    --couleur-po-2:             #{$bleu-2};
    --couleur-ml-1:             #{$bleu};           // Maine Libre
    --couleur-ml-2:             #{$bleu-2};
    
    --couleur-vv-1:             #{$bleu-vv};        // Voiles & voiliers
    --couleur-vv-2:             #{$bleu-vv-2};
        
    --couleur-im-1:             #{$rouge-im};       // Infomer Le Marin
    --couleur-im-2:             #{$rouge-im-2};
    
    --couleur-bc-1:             #{$bleu-bc};        // Bretons en cuisine
    --couleur-bc-2:             #{$bleu-bc-2};

    --couleur-br-1:             #{$noir-br};        // Bretons
    --couleur-br-2:             #{$noir-br-2};

    --couleur-vo-1:             #{$bleu-vo};        // Voyages à l'Ouest
    --couleur-vo-2:             #{$bleu-vo-2};

    --couleur-lm-1:             #{$rouge-lm};       // Lire Magazine
    --couleur-lm-2:             #{$rouge-lm-2};

    --couleur-tj-1:             #{$rouge-tj};       // Terre de jardin
    --couleur-tj-2:             #{$rouge-tj-2};
    
    --couleur-pdld-1:           #{$bleu-pdld};      // Prix de la Démocratie
    --couleur-pdld-2:           #{$anthracite};

    --couleur-ic-1:             #{$bleu-clair-ic};  // Infoconnect
    --couleur-ic-2:             #{$gris-bleu-ic};
    --couleur-ic-3:             #{$gris-bleu-ic-2};
    
    
    // RÉSEAUX SOCIAUX ET MARQUES EXTERNES
    --apple:             #000;
    --dailymotion:       #333436;
    --facebook:          #1877f2;
    --flickr:             #0063dc;
    --flipboard:          #e12828;
    --fluxrss:            #f0801a;
    --google:            #db4437;
    --instagram:         #fd0075;
    --linkedin:          #2867b2;
    --pinterest:         #bd081c;
    --snapchat:          #fffc00;
    --soundcloud:        #f50;
    --tumblr:            #000;
    --twitch:            #6441a4;
    --twitter:           #1da1f2;
    --viadeo:            #f07355;
    --x:                 #000;
    --youtube:           #f00;
}

// TYPO ----------------------------------------------------------------------------------
:root {
    --font-size-xs:   1.2rem;
    --font-size-s:    1.4rem;
    --font-size-ms:   1.6rem;
    --font-size-m:   1.8rem;
    --font-size-ml:   2rem;
    --font-size-l:   2.2rem;
    --font-size-xl:    2.4rem;
    --font-size-xxl:   3rem;
    --font-size-xxxl:  4rem;
    
    --font-weight-thin:   200;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold:   700;
    
    --line-height-xs: 1;
    --line-height-s:  1.2;
    --line-height-m:  1.35;
    --line-height-l:  1.5;
    --line-height-xl: 2;
}

// HAUTEUR STANDARD ----------------------------------------------------------------------
$size-touch: 44px;
$size-touch-sm: 34px;
:root {
    --taille-tactile: #{$size-touch};
    --taille-tactile-mini: #{$size-touch-sm};
}

// MARGES --------------------------------------------------------------------------------
// Marges en PX
:root {
    --marge-xs:    5px;
    --marge-s:     10px;
    --marge-m:     20px;
    --marge-l:     30px;
    --marge-xl:    40px;
    --marge-xxl:   50px;
}



// disabled
:root {
    --opacite-inactif: .5;
}
