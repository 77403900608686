/*
    Styles du composant [selects] de SipaUI
*/
@import "variables";

.sipaui {
    .su-select {
        position: relative;

        select {
            font-size: $font-size-standard;
            @include appearance(none);
            display: block;
            width: 100%;
            min-width: 140px;
            height: $height-select;
            border-radius:  $border-radius-select;
            color: $color-select;
            background: $background-select;
            border: $border-select;
            transition: all .3s ease-out;
            padding: 0 var(--taille-tactile) 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
                background: $background-select-hover;
                border: $border-select-hover;
            }
            &:focus {
                background: $background-select-focus;
                border: $border-select-focus;
            }

            option {
                color: $color-select;
            }
            
            &:invalid {
                color: $color-select-placeholder;
            }
        }
        &::before {
            content: "\e901";
            font-family: 'su-icons';
            color: $color-picto;
            top: 0.6rem;
            right: 1rem;
            position: absolute;
            font-size: $font-size-icons-standard;
            pointer-events: none;
        }
        &:not(.su-disabled):hover::before {
            color: $color-picto-hover;
        }
        &:not(.su-disabled):focus,
        &:not(.su-disabled):active {
            &::before {
                color: $color-picto-active;
            }
        }
        &.su-small {
            select {
                height: $height-select-small;
            }
            &::before {
                font-size: 1.9rem;
                top: 0.4rem;
            }
        }
    }
}
