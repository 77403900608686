/*
    Styles du composant [timeline] de SipaUI
*/
@import "variables";

.sipaui {
    .su-timeline {
        > li:not(:last-child) {
            .su-articles-list-item::before {
                content: "";
                position: absolute;
                left: $left-timeline-line;
                top: $top-timeline-line;
                bottom: $bottom-timeline-line;
                width: $width-timeline-line;
                background: $background-timeline-line;
            }
            .su-title {
                padding: $padding-timeline-title;
                margin: $margin-timeline-title;
            }
        }
        .su-articles-list-item {
            display: flex;
            column-gap: $column-gap-timeline;
        }
        .su-metadata {
            flex: 0 0 $flex-basis-timeline-metadata;
            column-gap: unset;
            justify-content: space-between;
            
            time:first-child {
                text-align: right;
                flex: 0 0 $flex-basis-timeline-metadata;
            }
        }
        .su-date {
            &::before {
                display: none;
            }
        }
        .su-title {
            flex: 1;
            font-weight: $font-weight-timeline-title;
            font-size: $font-size-timeline-title;
            transform: $transform-timeline-title;
        }
    }
}

// Fond Sombre
.sipaui {
    .su-negative .su-timeline,
    .su-negative.su-timeline {
        > li {
            .su-articles-list-item::before {
                background: $background-timeline-line-negative;
            }
        }
    }
}
