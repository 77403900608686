/*
    Styles du composant [lists] de SipaUI
*/
@import "variables";

$size-withdrawal-ul: $size-bullet + $margin-bullet;
$padding-bottom-li: 10px;


.sipaui {
    ul, ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    // LISTE A PUCE ------------------------------------------------------------------------------------------/
    .su-article ul:not(.su-not-list),
    ul.su-list {
        padding-left: $size-withdrawal-ul;

        > li {
            padding-bottom: $padding-bottom-li;

            &::before {
                content: "";
                display: inline-block;
                background: $color-bullet;
                width: $size-bullet;
                height: $size-bullet;
                border-radius: 100%;
                margin-left: - $size-withdrawal-ul;
                margin-right: $margin-bullet;
            }
            > ul:not(.su-not-list),
            > ul.su-list {
                padding-left: $size-withdrawal-ul * 2;
                margin-top: $padding-bottom-li;

                > li::before {
                    width: $size-bullet-n2;
                    height: $size-bullet-n2;
                }
            }
        }
    }
    
    // Petits textes
    .su-text-small ul.su-list,
    ul.su-list.su-text-small {
        > li {
            &::before {
                width: $size-bullet-s;
                height: $size-bullet-s;
                margin-right: $margin-bullet-s;
            }
            > ul:not(.su-not-list),
            > ul.su-list {
                > li::before {
                    width: $size-bullet-n2-s;
                    height: $size-bullet-n2-s;
                    margin-right: $margin-bullet-s;
                }
            }
        }
    }
    .su-text-xsmall ul.su-list,
    ul.su-list.su-text-xsmall {
        > li {
            &::before {
                width: $size-bullet-xs;
                height: $size-bullet-xs;
                margin-right: $margin-bullet-xs;
            }
            > ul:not(.su-not-list),
            > ul.su-list {
                > li::before {
                    width: $size-bullet-n2-xs;
                    height: $size-bullet-n2-xs;
                    margin-right: $margin-bullet-xs;
                }
            }
        }
    }
    
    // Cas du texte léger
    .su-article .su-text-light ul:not(.su-not-list),
    .su-article.su-text-light ul:not(.su-not-list),
    .su-article ul.su-text-light,
    ul.su-list.su-text-light {
        li::before {
            background: $font-color-light;
        }
    }
    
    @if $do-not-use-negative == null {
        // Cas du texte sur fond sombre
        .su-article .su-negative ul:not(.su-not-list),
        .su-article.su-negative ul:not(.su-not-list),
        .su-article ul.su-negative,
        ul.su-list.su-negative,
        .su-negative ul.su-list {
            li::before {
                background: $color-bullet-negative;
            }
        }
    }

    // LISTE ORDONNÉÉ ------------------------------------------------------------------------------------------/

    .su-article ol:not(.su-not-list),
    ol.su-list {
        display: table;
        counter-reset: liste;
        padding-left: 0;

        > li {
            display: table-row;
            counter-increment: liste;

            &::before {
                content: counter(liste)". ";
                display: table-cell;
                padding-right: 1rem;
                text-align: right;
                padding-bottom: $padding-bottom-li;
                font-weight: var(--font-weight-bold);
            }
            > ol:not(.su-not-list),
            > ol.su-list {
                display: block;
                list-style-type: lower-alpha;
                padding-left: $size-withdrawal-ul * 2;
                margin-top: $padding-bottom-li;

                > li {
                    display: list-item;
                    padding-bottom: $padding-bottom-li;

                    &::before {
                        content: '';
                        padding: 0;
                        display: none;
                    }
                }
            }
        }
    }
}

