/*
    Styles du composant [show-more-button] de SipaUI
*/
@import "variables";

.sipaui {
    .su-show-more {
        span+span {
            display: none;
        }
        &.su-active {
            span:first-child {
                display: none;
            }
            span+span {
                display: inline-block;
            }
        }
    }
}
