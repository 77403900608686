/*
    Styles du composant [media-icons] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    .su-button-fullscreen,
    .su-button-slideshow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        -webkit-user-select: none;
        -moz-user-select:    none;
        -ms-user-select:     none;
        user-select:         none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        
        span {
            position: absolute;
            width: $size-button-fullscreen-background;
            height: $size-button-fullscreen-background;
            top: $top-button-fullscreen-background;
            right: $right-button-fullscreen-background;
            transform: translate($translateX-button-fullscreen-background, $translateY-button-fullscreen-background);
            background: $background-button-fullscreen-background;
            border-radius: $border-radius-button-fullscreen-background;
            // Pour rendre invisible le texte à l’intérieur
            overflow: hidden;
            color: $background-button-fullscreen-background;
            font-size: 1px;
            
            &::before {
                content: "";
                font-family: su-icons!important;
                font-size: $font-size-button-fullscreen;
                color: $color-button-fullscreen;
                position: absolute;
                width: $size-button-fullscreen;
                height: $size-button-fullscreen;
                line-height: $size-button-fullscreen;
                top: ($size-button-fullscreen-background - $size-button-fullscreen)/2;
                right: ($size-button-fullscreen-background - $size-button-fullscreen)/2;
             }
        }
        &:hover span {
            opacity: $opacity-button-fullscreen-hover;
            background: $background-button-fullscreen-background-hover;
        }
    }
    .su-button-fullscreen span::before {        
        content: "\e911";
        transform: translate(-2px, -1px); //pour compenser le décentrage du texte
    }
    .su-button-slideshow span::before {        
        content: "\e910";
        transform: translate(-2px, -1px); //pour compenser le décentrage du texte
    }
}
