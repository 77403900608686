/*
    Styles du composant [titles] de SipaUI
*/
@import "variables";


// TITRES NON-ÉDITORIAUX------------------------------------------------------------------------------------------/
.sipaui {
    h1,
    .su-h1,
    h2,
    .su-h2,
    h3,
    .su-h3 {
        font-family: $font-family-titles;
        color: $color-titles;
        margin: 0;
        padding: 0;
        display: block;
    }
    h1,
    .su-h1,
    h2,
    .su-h2,
    h3,
    .su-h3,
        h1 b,
        .su-h1 b,
        h2 b,
        .su-h2 b,
        h3 b,
        .su-h3 b,
    h1 strong,
    .su-h1 strong,
    h2 strong,
    .su-h2 strong,
    h3 strong,
    .su-h3 strong,
        h1 i,
        .su-h1 i,
        h2 i,
        .su-h2 i,
        h3 i,
        .su-h3 i,
    h1 em,
    .su-h1 em,
    h2 em,
    .su-h2 em,
    h3 em,
    .su-h3 em,
        h1 q,
        .su-h1 q,
        h2 q,
        .su-h2 q,
        h3 q,
        .su-h3 q  {
        font-weight: $font-weight-titles;
        line-height: $line-height-titles;
        text-transform: $text-transform-titles;
    }
    h1,
    .su-h1 {
        font-family: $font-family-h1;
        font-weight: $font-weight-h1;
        font-size: $font-size-h1;
        color: $color-h1;
        line-height: $line-height-h1;
        text-transform: $text-transform-h1;
        
        &.su-with-margin {
            margin: $margin-h1-not-article;
        }
    }
    h2,
    .su-h2 {
        font-family: $font-family-h2;
        font-weight: $font-weight-h2;
        font-size: $font-size-h2;
        color: $color-h2;
        line-height: $line-height-h2;
        text-transform: $text-transform-h2;
        
        &.su-with-margin {
            margin: $margin-h2-not-article;
        }
    }
    h3,
    .su-h3 {
        font-family: $font-family-h3;
        font-weight: $font-weight-h3;
        font-size: $font-size-h3;
        color: $color-h3;
        line-height: $line-height-h3;
        text-transform: $text-transform-h3;
        
        &.su-with-margin {
            margin: $margin-h3-not-article;
        }
    }
    
    // Titres avec liens
    h2,
    .su-h2 {
        &.su-with-link {
            a {
                color: $color-titles; // au cas où il n’y a pas de couleur définie pour h2
                color: $color-h2;
                
                &:hover {
                    color: $color-titles-hover;
                    color: $color-h2-hover;
                }
            }
        }
    }
    h3,
    .su-h3 {
        &.su-with-link {
            a {
                color: $color-titles; // au cas où il n’y a pas de couleur définie pour h3
                color: $color-h3;
                
                &:hover {
                    color: $color-titles-hover;
                    color: $color-h3-hover;
                }
            }
        }
    }
    
    h2,
    .su-h2,
    h3,
    .su-h3 {
        &.su-with-link {
            a {
                position: relative;
                display: inline-block;
                padding-right: $padding-right-h2-h3-with-link;
                
                &::after {
                    content: "aller";
                    font-family: su-icons;
                    font-size: $font-size-h2-picto;
                    text-transform: none;
                    position: absolute;
                    transform: $transform-h2-picto;
                    transition: $transition-h2-h3-picto-hover;
                }
                &:hover {
                    &::after {
                        transform: $transform-h2-picto-hover
                    }
                }
            }
        }
    }
    h3,
    .su-h3 {
        &.su-with-link {
            a { 
                &::after {
                    font-size: $font-size-h3-picto;
                    transform: $transform-h3-picto;
                }
                &:hover {
                    &::after {
                        transform: $transform-h3-picto-hover
                    }
                }
            }
        }
    }
    
    
    @if $do-not-use-negative == null {
        // Couleur négative
        &.su-negative,
        .su-negative {
            h1,
            .su-h1,
            h2,
            .su-h2,
            h3,
            .su-h3 {
                color: $color-titles-negative;
            }
            h2,
            .su-h2,
            h3,
            .su-h3 {
                a {
                    color: $color-titles-negative;
                    
                    &:hover,
                    &:focus,
                    &:active {
                        color: $color-titles-negative-hover;
                    }
                }
            }
        }
        h1,
        .su-h1,
        h2,
        .su-h2,
        h3,
        .su-h3 {
            &.su-negative {
                color: $color-titles-negative;
            }
        }
        h2,
        .su-h2,
        h3,
        .su-h3 {
            &.su-negative {
                a {
                    color: $color-titles-negative;
                    
                    &:hover,
                    &:focus,
                    &:active {
                        color: $color-titles-negative-hover;
                    }
                }
            }
        }
    }
}

// TITRES LISTE ARTICLES ------------------------------------------------------------------------------------------/
.sipaui {
    .su-articles-list,
    .su-focus-big {
        h2,
        h3,
        .su-title {
            font-family: $font-family-titles-liste-articles;
            font-size: $font-size-titles-liste-articles;
            font-weight: $font-weight-titles-liste-articles;
            line-height: $line-height-titles-liste-articles;
            color: $color-titles-liste-articles;
            text-transform: $text-transform-titles-liste-articles;
            margin: 0;
            padding: 0;
        }
    }
    
    @if $do-not-use-negative == null {
        // Couleur négative
        .su-articles-area,
        .su-articles-list {
            &.su-negative,
            .su-negative {
                h2,
                h3,
                .su-title {
                    color: $color-titles-negative;
                }
            }
            h2,
            h3,
            .su-title {
                &.su-negative {
                    color: $color-titles-negative;
                }
            }
        }
    }
}


// TITRES ARTICLE COMPLET------------------------------------------------------------------------------------------/
.sipaui {
    .su-article {
        h1,
        .su-h1,
        h2,
        .su-h2,
        h3,
        .su-h3,
            h1 b,
            .su-h1 b,
            h2 b,
            .su-h2 b,
            h3 b,
            .su-h3 b,
        h1 strong,
        .su-h1 strong,
        h2 strong,
        .su-h2 strong,
        h3 strong,
        .su-h3 strong,
            h1 i,
            .su-h1 i,
            h2 i,
            .su-h2 i,
            h3 i,
            .su-h3 i,
        h1 em,
        .su-h1 em,
        h2 em,
        .su-h2 em,
        h3 em,
        .su-h3 em,
            h1 q,
            .su-h1 q,
            h2 q,
            .su-h2 q,
            h3 q,
            .su-h3 q  {
            font-family: $font-family-titles-article;
            font-weight: $font-weight-titles-article;
            line-height: $line-height-titles-article;
            color: $color-titles-article;
            text-transform: $text-transform-titles-article;
            padding: 0;
            font-style: normal;
        }
        h1,
        .su-h1,
            h1 b,
            .su-h1 b,
        h1 strong,
        .su-h1 strong,
            h1 i,
            .su-h1 i,
        h1 em,
        .su-h1 em,
            h1 q,
            .su-h1 q {
            font-family: $font-family-h1-article;
            font-weight: $font-weight-h1-article;
            font-size: $font-size-h1-article;
            color: $color-h1-article;
            line-height: $line-height-h1-article;
            text-transform: $text-transform-h1-article;
            margin: $margin-h1-article;
        }
        h2,
        .su-h2,
            h2 b,
            .su-h2 b,
        h2 strong,
        .su-h2 strong,
            h2 i,
            .su-h2 i,
        h2 em,
        .su-h2 em,
            h2 q,
            .su-h2 q {
            font-family: $font-family-h2-article;
            font-weight: $font-weight-h2-article;
            font-size: $font-size-h2-article;
            color: $color-h2-article;
            line-height: $line-height-h2-article;
            text-transform: $text-transform-h2-article;
            margin: $margin-h2-article;
        }
        h3,
        .su-h3,
            h3 b,
            .su-h3 b,
        h3 strong,
        .su-h3 strong,
            h3 i,
            .su-h2 i,
        h3 em,
        .su-h3 em,
            h3 q,
            .su-h3 q {
            font-family: $font-family-h3-article;
            font-weight: $font-weight-h3-article;
            font-size: $font-size-h3-article;
            color: $color-h3-article;
            line-height: $line-height-h3-article;
            text-transform: $text-transform-h3-article;
            margin: $margin-h3-article;
        }
        h1 + h2,
        .su-h1 + .su-h2,
        h2 + h3,
        .su-h2 + .su-h3,
        h2:first-child,
        .su-h2:first-child {
            margin-top: 0;
        }
        @if $do-not-use-negative == null {
            &.su-negative,
            .su-negative {
                h1,
                .su-h1,
                h2,
                .su-h2,
                h3,
                .su-h3 {
                    color: $color-titles-negative;
                }
            }
            h1,
            .su-h1,
            h2,
            .su-h2,
            h3,
            .su-h3 {
                &.su-negative {
                    color: $color-titles-negative;
                }
            }
        }
    }
}


//
/* -- DESKTOP ONLY ---------------------------------------------------------------------------------------------------------------------------------- */
//

@media only screen and (min-width: $min-breakpoint-lg) {
    // TITRES NON-ÉDITORIAUX------------------------------------------------------------------------------------------/
    .sipaui {
        h1,
        .su-h1 {
            font-size: $font-size-h1-desktop;
            
            &.su-with-margin {
                margin: $margin-h1-not-article-desktop;
            }
        }
        h2,
        .su-h2 {
            font-size: $font-size-h2-desktop;
            
            &.su-with-margin {
                margin: $margin-h2-not-article-desktop;
            }
        }
        h3,
        .su-h3 {
            font-size: $font-size-h3-desktop;
            
            &.su-with-margin {
                margin: $margin-h3-not-article-desktop;
            }
        }
    }

    // TITRES AVEC LIEN------------------------------------------------------------------------------------------/
    .sipaui {
        h2,
        .su-h2 {
            &.su-with-link {
                a { 
                    &::after {
                        transform: $transform-h2-picto-desktop;
                    }
                    &:hover {
                        &::after {
                            transform: $transform-h2-picto-hover-desktop;
                        }
                    }
                }
            }
        }
        h3,
        .su-h3 {
            &.su-with-link {
                a { 
                    &::after {
                        transform: $transform-h3-picto-desktop;
                    }
                    &:hover {
                        &::after {
                            transform: $transform-h3-picto-hover-desktop;
                        }
                    }
                }
            }
        }
    }
    
    // TITRES ÉDITORIAUX------------------------------------------------------------------------------------------/
    .sipaui {
        .su-article {
            h1,
            .su-h1,
                h1 b,
                .su-h1 b,
            h1 strong,
            .su-h1 strong,
                h1 i,
                .su-h1 i,
            h1 em,
            .su-h1 em,
                h1 q,
                .su-h1 q {
                font-size: $font-size-h1-article-desktop;
                margin: $margin-h1-article-desktop;
            }
            h2,
            .su-h2,
                h2 b,
                .su-h2 b,
            h2 strong,
            .su-h2 strong,
                h2 i,
                .su-h2 i,
            h2 em,
            .su-h2 em,
                h2 q,
                .su-h2 q {
                font-size: $font-size-h2-article-desktop;
                margin: $margin-h2-article-desktop;
            }
            h3,
            .su-h3,
                h3 b,
                .su-h3 b,
            h3 strong,
            .su-h3 strong,
                h3 i,
                .su-h3 i,
            h3 em,
            .su-h3 em,
                h3 q,
                .su-h3 q {
                font-size: $font-size-h3-article-desktop;
                margin: $margin-h3-article-desktop;

            }
        }
    }
}
