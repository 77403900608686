/*
    Styles du composant [checkboxes] de SipaUI
*/
@import "variables";

$height-input-checkbox:        20px;
$height-checkbox-area:         34px;
$line-height-label:         $line-height-standard;
$padding-top-label:         6px;
$padding-left-label: $height-input-checkbox + 7px;

.sipaui {
    .su-checkbox-area {
        display: flex;

        &.su-disabled {
            label,
            su-label,
            input,
            .su-input-checkbox {
                cursor: default;
            }
        }
        & .su-checkbox-area {
            padding-left: $padding-left-label;
        }
        + .su-error-message {
            margin-top: var(--marge-xs);
        }
    }
    .su-checkbox-area.su-checkbox-column {
        flex-direction: column;
        align-items: flex-start;

        label,
        .su-label-checkbox,
        > li {
            margin-bottom: var(--marge-xs);
            margin-left: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .su-label-checkbox,
    .su-checkbox-area label {
        color: $color-label-checkbox;
        font-size: $font-size-label-checkbox;
        font-weight: $font-weight-label-checkbox;
        height: auto;
        min-height: $height-checkbox-area;
        line-height: $line-height-label;
        padding-top: $padding-top-label;
        padding-left: $padding-left-label;
        margin: 0;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        & + label {
            margin-left: var(--marge-m);
        }
        & + span {
            display: inline-block;
            overflow: auto; // utile pour mettre un contexte de block pour FireFox
            line-height: $line-height-label;
            margin: 0 var(--marge-m);
            min-height: $height-checkbox-area;
            padding-top: $padding-top-label;
        }
    }
    input[type="checkbox"],
    .su-input-checkbox {
        @include appearance(none);

        outline: none;
        cursor: pointer;
    }
    .su-checkbox-area  input[type="checkbox"],
    .su-input-checkbox {
        border-radius: 3px;
        width: $height-input-checkbox;
        height: $height-input-checkbox;
        transition: 0.2s all linear;
        position: absolute;
        top: 7px;
        left: 0;
        background: $background-input-checkbox;
        border: $border-input-checkbox;

        &::before {
            content: "";
            display: block;
            width: $height-input-checkbox;
            height: $height-input-checkbox;
            position: absolute;
            top: -1px;
            left: -1px;
        }
        &:checked,
        &.su-indeterminate  {
            border: $border-input-checkbox-checked;
            background: $background-input-checkbox-checked;
        }
        &.su-indeterminate::before {
            background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FscXVlXzEiIGRhdGEtbmFtZT0iQ2FscXVlIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwIDIiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+dGlyZXQ8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjEwIiBoZWlnaHQ9IjIiLz48L3N2Zz4=) center no-repeat;
            background-size: 10px;
        }
        &:checked::before {
            background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FscXVlXzEiIGRhdGEtbmFtZT0iQ2FscXVlIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIyLjk0IDI1LjA2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnZhbGlkZXI8L3RpdGxlPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMS45NyAyNS4wNSAwIDEzLjA4IDIuMTIgMTAuOTYgMTEuMDUgMTkuODkgMjAuMjIgMCAyMi45NCAxLjI2IDExLjk3IDI1LjA1Ii8+PC9zdmc+) center no-repeat;
            background-size: 14px;
        }
    }
}
