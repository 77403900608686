/*
    Fonte d’icônes
*/

@if $font-import-use-local == null {
    @font-face {
      font-family: 'su-icons';
      src:
        url('https://cdn.sipaof.fr/sipaui/latest/fonts/su-icons/su-icons.woff2?c8ge7g') format('woff2');
      font-weight: normal;
      font-style: normal;
      @if $icons-display-optional != null {
          font-display: optional;
      }
    }
} @else {
    @font-face {
      font-family: 'su-icons';
      src:
        url('#{$font-import-directory}/su-icons/su-icons.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      @if $icons-display-optional != null {
          font-display: optional;
      }
    }
}

[class*="su-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'su-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Gestion de la taille par defaut */
.su-icon {
    font-size: $font-size-icons-standard;
}
/* Gestion de la taille mini */
.su-icon-small {
    font-size: $font-size-icons-small;
}
