/*
    Typographie
*/

$margin-paragraphe: var(--marge-m);


// TEXTE EDITORIAL STANDARD--------------------------------------------------------------------------------------------------------------------------------------------------------------------------/
.sipaui {
    font-family: $font-family-base;
    font-size: $font-size-standard;
    line-height: $line-height-standard;
    color: $font-color-standard;

    .su-text-standard {
        font-family: $font-family-base;
        font-size: $font-size-standard;
        line-height: $line-height-edito;
        color: $font-color-standard;
    }
    .su-text-standard + .su-text-standard {
        margin-top: $margin-paragraphe;
    }
    .su-article,
    .su-articles-area,
    .su-articles-list {
        font-family: $font-family-base;
        font-size: $font-size-standard;
        line-height: $line-height-edito;
        color: $font-color-standard;
    }
    .su-article {
        p + p,
        ul + p,
        ol + p {
            margin-top: $margin-paragraphe;
        }
    }
    .su-text-xsmall {
        font-size: var(--font-size-xs);
        line-height: $line-height-standard;
    }
    .su-text-small {
        font-size: var(--font-size-s);
        line-height: $line-height-standard;
    }
    .su-text-medium {
        font-size: var(--font-size-ms);
        line-height: $line-height-standard;
    }
    .su-text-big {
        font-size: var(--font-size-m);
        line-height: $line-height-standard;
    }
    .su-text-light {
        color: $font-color-light;
    }
    .su-text-xlight {
        color: $font-color-xlight;
    }
    
    @if $do-not-use-negative == null {
        // Couleur négative
        &.su-negative,
        .su-negative {
            color: $font-color-negative;
            
            .su-text-standard {
            color: $font-color-negative;
            }
        }
        .su-text-standard.su-negative,
        .su-article.su-negative,
        .su-articles-area.su-negative,
        .su-articles-list.su-negative {
            color: $font-color-negative;
        }
        
        
        &.su-negative .su-text-light,
        .su-negative .su-text-light,
        .su-text-light.su-negative {
            color: $font-color-light-negative;
        }
        &.su-negative .su-text-xlight,
        .su-negative .su-text-xlight,
        .su-text-xlight.su-negative {
            color: $font-color-xlight-negative;
        }
    }
}

// LIEN STANDARD ------------------------------------------------------------------------------------------/
.sipaui {
    a {
        color: $color-link-standard;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: $color-link-standard-hover;
            text-decoration: none;
        }
        &:visited {
            color: $color-link-standard;
        }
    }
    a:not([href]):not([tabindex]) { // Voir https://github.com/twbs/bootstrap/issues/19402 (pour ne pas styler les balises <a> sans href)
        color: inherit;
        text-decoration: none;
        border-bottom: none;

        &:hover,
        &:focus,
        &:active {
            color: inherit;
            text-decoration: none;
            border-bottom: none;
        }
        &:focus {
            outline: none;
        }
        &:visited {
            color:inherit;
        }
    }
    
    @if $do-not-use-negative == null {
        // Couleur négative
        .su-negative,
        .su-negative .su-text-standard,
        .su-text-standard.su-negative,
        .su-article.su-negative,
        .su-articles-area.su-negative,
        .su-articles-list.su-negative {
            a {
                color: $color-link-negative;

                &:hover,
                &:focus,
                &:active {
                    color: $color-link-negative-hover;
                }
                &:visited {
                    color: $color-link-negative;
                }
            }
        }
    }
}
