/*
    Styles du composant [loaders] de SipaUI
*/
@import "variables";

$size-loader-big: 44px;
$thickness-loader-big: 5px;

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sipaui {

    // LOADER BASE
    .su-loader {
        display: inline-block;
        border: $thickness-loader-big solid $color-main-loader-big;
        border-left-color: $color-secondary-loader-big;
        border-radius: 50%;
        width: $size-loader-big;
        height: $size-loader-big;
        animation: rotation .8s linear 0s infinite;
    }
    // LOADER DE BLOC ET DE PAGE
    .su-bloc-loader,
    .su-page-loader {
        background: rgba(255,255,255, .9);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10100;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .su-bloc-loader {
        position: absolute;
    }
    .su-page-loader {
        position: fixed;
    }
}


