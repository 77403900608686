/*
    Styles du composant [pop-ins] de SipaUI
*/
@import "variables";

.sipaui {
    .su-veil {
        display: block;
        position: static;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        z-index: 10200;
        background: $background-veil;
        opacity: 0;
        transition: $animation-veil;

        &.su-veil-in {
            opacity: $opacity-veil;
            position: fixed;
        }
    }
}
