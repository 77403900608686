/*
    Styles du composant [focus] de SipaUI
*/
@import "variables";


// FOCUS STANDARD
.sipaui {
    .su-focus {
        .su-media-container,
        > figure { // a supprimer en v7 (su-media-container obligatoire)
            margin-bottom: $margin-bottom-figure-focus;
        }
        .su-title {
            margin-bottom: $margin-bottom-titre-focus;
        }
    }
}
@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-focus {
            figure:has(.su-visually-hidden) {
                display: none;
                
                + [class*="su-media-icons"] {
                    display: none;
                }
            }
        }
    }
}

// GRAND FOCUS
.sipaui {
    .su-focus-big {
        display: flex;
        flex-flow: column nowrap;
        
        .su-media-container,
        > figure { // a supprimer en v7 (su-media-container obligatoire)
            order: 3;
        }
        figure:has(.su-visually-hidden) { // Cas où l’image est absente et remplacée par un span avec su-visually-hidden
            display: none;
            
            + [class*="su-media-icons"] {
                display: none;
            }
        }
        .su-title {
            order: 1;
            margin-bottom: $margin-bottom-titre-grand-focus;
            font-size: $font-size-titre-grand-focus;
            line-height: $line-height-titre-grand-focus     
        }
        .su-metadata {
            order: 2;
            margin-bottom: $margin-bottom-metadata-grand-focus;
        }
    }
}
@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-focus-big {
            .su-title {
                font-size: $font-size-titre-grand-focus-md;
            }
        }
    }
}
@media screen and (min-width: $min-breakpoint-xl) {
    .sipaui {
        .su-focus-big {
            .su-title {
                font-size: $font-size-titre-grand-focus-xl;
            }
        }
    }
}

// FOCUS IMAGE
.sipaui {
    .su-focus-image {
        overflow: hidden;
        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $background-focus-image;
            border-radius: $border-radius-focus-image;
        }
        
        .su-title,
        .su-metadata,
        [class*="su-media-icons"] {
            position: absolute;
            z-index: 1;
        }
        .su-title {
            bottom: 0;
            left: 0;
            width: 100%;
            padding: $padding-titre-focus-image;
            
            a {
                overflow: hidden;
                @supports (display: -webkit-box) {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
        .su-metadata {
            bottom: $bottom-metadata-focus-image;
            left: $left-metadata-focus-image;
        }
        .su-title a {
            color: $color-texte-focus-image;
            
            &:hover {
                color: $color-texte-hover-focus-image;
            }
        }
        &.su-max-line-2 {
            .su-title {
                a {
                    @supports (display: -webkit-box) {
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
    }
}

@supports (display: -webkit-box) {
    .sipaui .su-focus-image.su-premium .su-title a {
        display: inline;
    }
}
