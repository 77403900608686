/*
    Styles du composant [baseline] de SipaUI
*/
@import "variables";


.sipaui {
    .su-baseline {
        font-family: $font-family-base;
        font-size: $font-size-standard;
        font-weight: $font-weight-standard;
        font-style: normal;
        line-height:$line-height-standard ;
        color: $color-baseline;
        
        @if $do-not-use-negative == null {
            &.su-negative {
                color: $color-baseline-negative;
            }
        }
    }
}

