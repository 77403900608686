/*
    Styles du composant [breadcrumb] de SipaUI
*/
@import "variables";


// GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-breadcrumb {
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-breadcrumb;
        line-height: $line-height-breadcrumb;
                                                                            
        li {
            position: relative;
            margin-right: $margin-right-breadcrumb-item;
            padding-right: $padding-right-breadcrumb-item;
             
             &:not(:last-child)::after {
                content: '\e915';
                font-family: 'su-icons';
                font-size: $font-size-icons-standard;
                position: absolute;
                top: $top-breadcrumb-separator;
                right: $right-breadcrumb-separator;
                color: $color-breadcrumb-separator;
             }
        }
        a {
            color: $color-breadcrumb-item;
            
            &:hover,
            &:focus,
            &:active {
                color: $color-breadcrumb-item-hover;
                opacity: $opacity-breadcrumb-item-hover;
            }
        } 
    }
    .su-negative .su-breadcrumb,
    .su-breadcrumb.su-negative {
        li:after,
        a,
        a:hover,
        a:focus,
        a:active  {
            color: $color-breadcrumb-item-negative;
        }
    }
}
