/*
    Styles du composant [blocquote] de SipaUI
*/
@import "variables";


// BLOCKQUOTE GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-article q {
        font-weight: $font-weight-q;
    }
    .su-article blockquote,
    .su-blockquote {
        max-width: 540px;
        margin: auto;
        padding: 0;
        padding-right: $padding-right-blockquote;
        color: $color-blockquote;
        font-size: $font-size-blockquote;
        font-weight: $font-weight-blockquote;
        line-height: $line-height-blockquote;
        text-align: $text-align-blockquote;
        
        > *:first-child:before,
        > p:nth-last-of-type(1):after,
        > div:nth-last-of-type(1):after {
            content: "citation";
            display: inline-block;
            font-size: $font-size-blockquote-quote-left;
            color: $color-main-4;
            font-family: su-icons!important;
            font-style: $font-style-blockquote-quote;
            font-weight: $font-weight-blockquote-quote;
            font-variant: normal;
            text-transform: none;
            line-height: 0;
            vertical-align: $vertical-align-blockquote-quote;
            -webkit-font-smoothing: antialiased;
            speak: none;
        }
        > p:nth-last-of-type(1):after,
        > div:nth-last-of-type(1):after {
            font-size: $font-size-blockquote-quote-right;
            vertical-align: middle;
            text-indent: -40px;
            transform: rotate(180deg);
        }
        p+p {
            margin-top: 0;
        }
        .su-author {
            margin-top: $margin-top-blockquote-author;
        }
    }
}

// BLOCKQUOTE DESKTOP ONLY -----------------------------------------------------------------------------------------/
@media only screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-article blockquote,
        .su-blockquote {
            font-size: $font-size-blockquote-desktop;
        }
    }
}

