/*
    Styles du composant [card] de SipaUI
*/
@import "variables";
@import "../../Text/Titles/variables";
@import "../../Text/Sources/variables";
@import "../../Text/Date/variables";


// FOCUS STANDARD
.sipaui {
    ol.su-ranked-list {
        counter-reset: rankedArticles;
        
        li {
            counter-increment: rankedArticles;
            display: flex;
            gap: $gap-ranked-articles-number;
            
            &::before {
                content: counter(rankedArticles);
                line-height: $line-height-ranked-articles-number;
                color: $color-ranked-articles-number;
                font-size: $font-size-ranked-articles-number;
            }
        }
        &.su-columns-no-cut {
            li {
                display: inline-flex;
            }
        }
    }
}
