/*
    Styles du composant [margins] de SipaUI
*/
@import "variables";

.sipaui {
    .su-margin-0 {
        margin: 0 !important;
    }
    .su-margin-xs {
        margin: var(--marge-xs) !important;
    }
    .su-margin-s {
        margin: var(--marge-s) !important;
    }
    .su-margin-m {
        margin: var(--marge-m) !important;
    }
    .su-margin-l {
        margin: var(--marge-l) !important;
    }
    .su-margin-xl {
        margin: var(--marge-xl) !important;
    }
    .su-margin-xxl {
        margin: var(--marge-xxl) !important;
    }

    .su-margin-top-0 {
        margin-top: 0 !important;
    }
    .su-margin-top-xs {
        margin-top: var(--marge-xs) !important;
    }
    .su-margin-top-s {
        margin-top: var(--marge-s) !important;
    }
    .su-margin-top-m {
        margin-top: var(--marge-m) !important;
    }
    .su-margin-top-l {
        margin-top: var(--marge-l) !important;
    }
    .su-margin-top-xl {
        margin-top: var(--marge-xl) !important;
    }
    .su-margin-top-xxl {
        margin-top: var(--marge-xxl) !important;
    }

    .su-margin-right-0 {
        margin-right: 0 !important;
    }
    .su-margin-right-xs {
        margin-right: var(--marge-xs) !important;
    }
    .su-margin-right-s {
        margin-right: var(--marge-s) !important;
    }
    .su-margin-right-m {
        margin-right: var(--marge-m) !important;
    }
    .su-margin-right-l {
        margin-right: var(--marge-l) !important;
    }
    .su-margin-right-xl {
        margin-right: var(--marge-xl) !important;
    }
    .su-margin-right-xxl {
        margin-right: var(--marge-xxl) !important;
    }

    .su-margin-bottom-0 {
        margin-bottom: 0 !important;
    }
    .su-margin-bottom-xs {
        margin-bottom: var(--marge-xs) !important;
    }
    .su-margin-bottom-s {
        margin-bottom: var(--marge-s) !important;
    }
    .su-margin-bottom-m {
        margin-bottom: var(--marge-m) !important;
    }
    .su-margin-bottom-l {
        margin-bottom: var(--marge-l) !important;
    }
    .su-margin-bottom-xl {
        margin-bottom: var(--marge-xl) !important;
    }
    .su-margin-bottom-xxl {
        margin-bottom: var(--marge-xxl) !important;
    }

    .su-margin-left-0 {
        margin-left: 0 !important;
    }
    .su-margin-left-xs {
        margin-left: var(--marge-xs) !important;
    }
    .su-margin-left-s {
        margin-left: var(--marge-s) !important;
    }
    .su-margin-left-m {
        margin-left: var(--marge-m) !important;
    }
    .su-margin-left-l {
        margin-left: var(--marge-l) !important;
    }
    .su-margin-left-xl {
        margin-left: var(--marge-xl) !important;
    }
    .su-margin-left-xxl {
        margin-left: var(--marge-xxl) !important;
    }
}

@media screen and (max-width: $max-breakpoint-sm) {
    .sipaui {
        .su-margin-bottom-0-sm-max {
            margin-bottom: 0 !important;
        }
        .su-margin-bottom-xs-sm-max {
            margin-bottom: var(--marge-xs) !important;
        }
        .su-margin-bottom-s-sm-max {
            margin-bottom: var(--marge-s) !important;
        }
        .su-margin-bottom-m-sm-max {
            margin-bottom: var(--marge-m) !important;
        }
        .su-margin-bottom-l-sm-max {
            margin-bottom: var(--marge-l) !important;
        }
        .su-margin-bottom-xl-sm-max {
            margin-bottom: var(--marge-xl) !important;
        }
        .su-margin-bottom-xxl-sm-max {
            margin-bottom: var(--marge-xxl) !important;
        }
    }
}

@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-margin-bottom-0-md-max {
            margin-bottom: 0 !important;
        }
        .su-margin-bottom-xs-md-max {
            margin-bottom: var(--marge-xs) !important;
        }
        .su-margin-bottom-s-md-max {
            margin-bottom: var(--marge-s) !important;
        }
        .su-margin-bottom-m-md-max {
            margin-bottom: var(--marge-m) !important;
        }
        .su-margin-bottom-l-md-max {
            margin-bottom: var(--marge-l) !important;
        }
        .su-margin-bottom-xl-md-max {
            margin-bottom: var(--marge-xl) !important;
        }
        .su-margin-bottom-xxl-md-max {
            margin-bottom: var(--marge-xxl) !important;
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-margin-bottom-0-md-min {
            margin-bottom: 0 !important;
        }
        .su-margin-bottom-xs-md-min {
            margin-bottom: var(--marge-xs) !important;
        }
        .su-margin-bottom-s-md-min {
            margin-bottom: var(--marge-s) !important;
        }
        .su-margin-bottom-m-md-min {
            margin-bottom: var(--marge-m) !important;
        }
        .su-margin-bottom-l-md-min {
            margin-bottom: var(--marge-l) !important;
        }
        .su-margin-bottom-xl-md-min {
            margin-bottom: var(--marge-xl) !important;
        }
        .su-margin-bottom-xxl-md-min {
            margin-bottom: var(--marge-xxl) !important;
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-margin-bottom-0-lg-min {
            margin-bottom: 0 !important;
        }
        .su-margin-bottom-xs-lg-min {
            margin-bottom: var(--marge-xs) !important;
        }
        .su-margin-bottom-s-lg-min {
            margin-bottom: var(--marge-s) !important;
        }
        .su-margin-bottom-m-lg-min {
            margin-bottom: var(--marge-m) !important;
        }
        .su-margin-bottom-l-lg-min {
            margin-bottom: var(--marge-l) !important;
        }
        .su-margin-bottom-xl-lg-min {
            margin-bottom: var(--marge-xl) !important;
        }
        .su-margin-bottom-xxl-lg-min {
            margin-bottom: var(--marge-xxl) !important;
        }
    }
}
